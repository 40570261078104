import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Headbar from './Headbar'
import Sidebar from './Sidebar'
import CredManagerModal from '../modal/CredManagerModal'
import { useUpdatePasswordMutation, useUpdatePinMutation } from '../../services/updateSlice'
import SidebarOffCanvas from './SidebarOffCanvas'
import CheckAndRefreshToken from '../CheckAndRefreshToken'

const Layout = () => {

    const [updatePin, updatePinResponse] = useUpdatePinMutation();
    const [updatePassword, updatePasswordResponse] = useUpdatePasswordMutation();

    const { pathname } = useLocation()

    useEffect(() => {
        const classList = document.body.classList
        if (classList.contains("modal-open")) classList.remove("modal-open")

        const backdrops = document.getElementsByClassName("modal-backdrop")
        for (const backdrop of backdrops) backdrop.remove()
    }, [pathname])

    return (

        <div className='h-100 w-100 overflow-hidden d-flex'>
            <Sidebar />
            <CheckAndRefreshToken />
            <div className='d-flex flex-column overflow-hidden flex-grow-1'>
            <SidebarOffCanvas />
                <Headbar />
                <main className='flex-grow-1 pagesBack overflow-hidden '>
                    <Outlet />
                </main>
            </div>
            <CredManagerModal updatePin={updatePin} updatePinResponse={updatePinResponse} updatePassword={updatePassword} updatePasswordResponse={updatePasswordResponse} />
        </div>
    )

}

export default Layout