import React, { useEffect, useState } from 'react'
import { Link, useResolvedPath } from 'react-router-dom'
import Icons from '../components/ui/Icons'
import WarningRedImg from '../assets/images/warning-red.png'
import WarningOrangeImg from '../assets/images/warning-orange.png'
import CalendarGreenImg from '../assets/images/calendar-green.png'
import UserBlueImg from '../assets/images/user-blue.png'
import { useAdminInsightsQuery } from '../services/insightSlice'
import Loading from '../components/ui/Loading'
import ApiErrorModal from '../components/modal/ApiErrorModal'
import { useGetRemindersQuery, usePostReminderMutation } from '../services/reminderSlice'
import DashReminder from '../components/pages/DashReminder'
import { useGetSupportTicketsQuery } from '../services/supportSlice'
import RemainderMemoModal from '../components/modal/RemainderMemoModal'
import { useRefreshTokenMutation } from '../services/authSlice'

const Dashboard = () => {
  const [unresolvedTickets, setUnresolvedTickets] = useState(null);

  const res = useAdminInsightsQuery();
  const remindersRes = useGetRemindersQuery();

  const supportRes = useGetSupportTicketsQuery();


  const [postRem, postRemResponse] = usePostReminderMutation();

  useEffect(() => {
    const fetchUnresolvedTickets = async () => {
      const data = await supportRes.data;
      const filterdata = data?.filter((item) => item.status == 'Created')

      setUnresolvedTickets(filterdata?.length);
    };

    fetchUnresolvedTickets();
  },);







  if (res.isLoading || remindersRes.isLoading || supportRes.isLoading) return <Loading />
  if (res.isError) return <ApiErrorModal response={res} />
  if (remindersRes.isError) return <ApiErrorModal response={remindersRes} />
  if (supportRes.isError) return <ApiErrorModal response={supportRes} />

  const rems = remindersRes.data

  // return (
  //   <div className="p-3 h-100">
  //     <div className="card p-4 d-flex flex-column bg-white bg-opacity-25 border-0 shadow rounded-4 h-100 overflow-hidden">

  //       <div className='bg-warning'>

  //         <div className='col-9'>
  //           <h3 className='fw-bold'><span className='text-danger'>Administrator </span>Dashboard</h3>
  //           <p>"Welcome to the Integrated Super-administrative Console for SGRS Utility Applications,
  //             your central hub for efficient management and optimization of SGRS utility services."</p>
  //         </div>

  //         {/* <div className="col-3 h-100">
  //           <div className='overflow-hidden rounded-5 d-flex flex-column shadow bg-white h-100 px-2'>
  //             <div className='d-flex justify-content-center'><div className="bg-primary px-2  text-center text-white py-2 rounded-bottom-4 ">Super Admin Navigator</div></div>
  //             <div className=' flex-grow-1'>adf</div>
  //           </div>
  //         </div> */}



  //       </div>

  //       <div className="row p-3">

  //         <div className="col-9 h-100 overflow-hidden">
  //           <div className='p-5 rounded-5 shadow position-relative mt-5 bg-white overflow-hidden h-100'>

  //             <div className="bg-primary text-white px-5 py-2 rounded-end-pill rounded-top-pill position-absolute top-0 start-0" style={{ marginTop: -20 }}>Control Center Summary</div>

  //             {/* <div className='d-flex align-items-center justify-content-between mb-4 pb-2 border-bottom'>
  //               <div className='me-4'>{Icons.warning('fs-1 text-warning')}</div>
  //               <div>Domain for ICGS Portal expiring this month  : <span className='fw-bold text-danger'>Check details</span> or fix this problem now</div>
  //               <div className=''>{Icons.check('fs-1')}</div>
  //             </div> */}

  //             <div className='py-3 overflow-auto h-100' >
  //               {(unresolvedTickets > 0) &&
  //                 <div className='d-flex align-items-center mb-4 pb-2 border-bottom'>
  //                   <div className='me-3'>{Icons.warning('fs-1 bg-red')}</div>
  //                   Number of Tickets Unresolved is {unresolvedTickets}     :  <Link style={{ color: '#4500D8' }} to={"/Tickets"}>Check details</Link>
  //                 </div>
  //               }

  //               {
  //                 rems
  //                   .filter((rem) => rem.status !== "Done")
  //                   .filter((rem) => {
  //                     if (rem.status === "Created" && new Date(rem.pointOne) < new Date()) return true
  //                     else if (rem.status === "Skipped Point 1" && new Date(rem.pointTwo) < new Date()) return true
  //                     else if (rem.status === "Skipped Point 2" && new Date(rem.endPoint) < new Date()) return true
  //                   })
  //                   .map((rem) => <DashReminder refetch={remindersRes.refetch} rem={rem} />)
  //               }
  //             </div>


  //           </div>
  //         </div>

  //         <div className="col-3 h-100">
  //           <div className='p-5 rounded-5 shadow position-relative mt-5 bg-white h-100'>

  //             <div className="bg-primary text-white px-3 py-2 rounded-end-pill rounded-top-pill position-absolute top-0 start-0" style={{ marginTop: -20 }}>Administrative Insights</div>
  //             <div className='text-center '>
  //               <p className='fs-1 fw-bold'>2</p>
  //               <p>Portals</p>

  //               <p className='fs-1 fw-bold'>{res.data.userCount}</p>
  //               <p>Utility Users</p>

  //               <p className='fs-1 fw-bold'>{res.data.adminCount}</p>
  //               <p>Utility Admins</p>

  //               <p className='fs-1 fw-bold'>{res.data.branchCount}</p>
  //               <p>Utility Branches</p>
  //             </div>

  //           </div>
  //         </div>

  //       </div>
  //     </div>

  //   </div >
  // )

  return (
    <>
      <div className='p-4 d-lg-flex d-none flex-column h-100 shadow'>

        <div className='p-4 card bg-opacity-25 bg-white rounded-4 border-white d-flex flex-column h-100 shadow'>
          <div className='mb-4'>
            <h3 className='fw-bold'><span className='text-danger'>Administrator </span>Dashboard</h3>
            <p>"Welcome to the Integrated Super-administrative Console for SGRS Utility Applications,
              your central hub for efficient management and optimization of SGRS utility services."</p>
          </div>

          <div className='d-flex flex-grow-1 overflow-hidden  h-100 p-3' >
            <div className='  w-75 me-3 pe-3   h-100  pt-3 '>
              <div className='p-5 rounded-5 shadow  bg-white overflow-hidde position-relative h-100 hide-scroll'>
                <div className="bg-primary py-2 px-3 text-white rounded-end-pill rounded-top-pill position-absolute" style={{ marginTop: -63, marginLeft: -47 }}>Control Center Summary</div>


                {/* <div className='d-flex align-items-center justify-content-between mb-4 pb-2 border-bottom'>
                <div className='me-4'>{Icons.warning('fs-1 text-warning')}</div>
                <div>Domain for ICGS Portal expiring this month  : <span className='fw-bold text-danger'>Check details</span> or fix this problem now</div>
                <div className=''>{Icons.check('fs-1')}</div>
              </div> */}

                <div className='py-2 overflow-y-auto h-100'>
                  {(unresolvedTickets > 0) &&
                    <div className='d-flex align-items-center mb-4 pb-2 border-bottom'>
                      <div className='me-3'>{Icons.warning('fs-1 bg-red')}</div>
                      Number of Tickets Unresolved is {unresolvedTickets}     :    <Link style={{ color: '#4500D8' }} to={"/Tickets"}>Check details</Link>
                    </div>
                  }

                  {
                    rems
                      .filter((rem) => rem.status !== "Done")
                      .filter((rem) => {
                        if (rem.status === "Created" && new Date(rem.pointOne) < new Date()) return true
                        else if (rem.status === "Skipped Point 1" && new Date(rem.pointTwo) < new Date()) return true
                        else if (rem.status === "Skipped Point 2" && new Date(rem.endPoint) < new Date()) return true
                      })
                      .map((rem) => <DashReminder refetch={remindersRes.refetch} rem={rem} />)
                  }
                </div>


              </div>
            </div>

            <div className='w-25 h-100 pt-3'>
              <div className='p-4 rounded-5 shadow bg-white h-100 overflow-hidde position-relative'>

                <div className="bg-primary text-white px-3 py-2 rounded-end-pill rounded-top-pill position-absolute" style={{ marginTop: -40, marginLeft: -25 }}>Administrative Insights</div>
                <div className='text-center d-flex flex-column justify-content-evenly bg-dange h-100'>
                  <div>
                    <p className='fs-1 fw-bold'>2</p>
                    <p>Portals</p>
                  </div>

                  <div>
                    <p className='fs-1 fw-bold'>{res.data.userCount}</p>
                    <p>Utility Users</p>
                  </div>

                  <div>
                    <p className='fs-1 fw-bold'>{res.data.adminCount}</p>
                    <p>Utility Admins</p>
                  </div>

                  <div>
                    <p className='fs-1 fw-bold'>{res.data.branchCount}</p>
                    <p>Utility Branches</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>






      <div className='p-4  d-lg-none d-flex flex-column h-100 overflow-auto shadow'>
        <div className='p-3 card bg-opacity-25 bg-white rounded-4 border-white d-flex flex-column shadow'>
          <div className='mb-4'>
            <h3 className='fw-bold'><span className='text-danger'>Administrator </span>Dashboard</h3>
            <p>"Welcome to the Integrated Super-administrative Console for SGRS Utility Applications,
              your central hub for efficient management and optimization of SGRS utility services."</p>
          </div>

          <div className='d-flex flex-column flex-grow-1 ' >
            <div className='pt-3 '>
              <div className='p-5 rounded-5 shadow  bg-white  position-relative h-100 hide-scroll'>
                <div className="bg-primary py-2 px-3 text-white rounded-end-pill rounded-top-pill position-absolute" style={{ marginTop: -63, marginLeft: -47 }}>Control Center Summary</div>


                {/* <div className='d-flex align-items-center justify-content-between mb-4 pb-2 border-bottom'>
                <div className='me-4'>{Icons.warning('fs-1 text-warning')}</div>
                <div>Domain for ICGS Portal expiring this month  : <span className='fw-bold text-danger'>Check details</span> or fix this problem now</div>
                <div className=''>{Icons.check('fs-1')}</div>
              </div> */}

                <div className='py-2 overflow-y-auto h-100'>
                  {(unresolvedTickets > 0) &&
                    <div className='d-flex align-items-center mb-4 pb-2 border-bottom'>
                      <div className='me-3'>{Icons.warning('fs-1 bg-red')}</div>
                      Number of Tickets Unresolved is {unresolvedTickets}     :    <Link style={{ color: '#4500D8' }} to={"/Tickets"}>Check details</Link>
                    </div>
                  }

                  {
                    rems
                      .filter((rem) => rem.status !== "Done")
                      .filter((rem) => {
                        if (rem.status === "Created" && new Date(rem.pointOne) < new Date()) return true
                        else if (rem.status === "Skipped Point 1" && new Date(rem.pointTwo) < new Date()) return true
                        else if (rem.status === "Skipped Point 2" && new Date(rem.endPoint) < new Date()) return true
                      })
                      .map((rem) => <DashReminder refetch={remindersRes.refetch} rem={rem} />)
                  }
                </div>


              </div>
            </div>

            <div className='pt-3 mt-5'>
              <div className='p-4 rounded-5 shadow bg-white h-100 overflow-hidde position-relative'>

                <div className="bg-primary text-white px-3 py-2 rounded-end-pill rounded-top-pill position-absolute" style={{ marginTop: -40, marginLeft: -25 }}>Administrative Insights</div>
                <div className='text-center d-flex flex-column justify-content-evenly bg-dange h-100'>
                  <div>
                    <p className='fs-1 fw-bold'>2</p>
                    <p>Portals</p>
                  </div>

                  <div>
                    <p className='fs-1 fw-bold'>{res.data.userCount}</p>
                    <p>Utility Users</p>
                  </div>

                  <div>
                    <p className='fs-1 fw-bold'>{res.data.adminCount}</p>
                    <p>Utility Admins</p>
                  </div>

                  <div>
                    <p className='fs-1 fw-bold'>{res.data.branchCount}</p>
                    <p>Utility Branches</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Dashboard