
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        adminInsights: builder.query({ query: () => `/adminInsights`, keepUnusedDataFor: 0 }),
    }),
})


export const { useAdminInsightsQuery } = extendedApiSlice
