import React, { useState } from 'react'
import SuccessModal from '../../components/modal/SuccessModal';
import ApiErrorModal from '../../components/modal/ApiErrorModal';
import { usePostAdminMutation } from '../../services/adminSlice';
import Check from '../../components/form/Check';
import Input from '../../components/form/Input';
import Bar from '../../components/form/Bar';
import Select from '../../components/form/Select';
import iconImg from '../../assets/images/icon.png'
import icon1Img from '../../assets/images/icon1.png'
import userPhoto from '../../assets/images/userPhoto.png'
import { PORTALS, POSTS, ROLES, TYPES } from '../../constants/Constants'
import ContainerCard from '../../components/ui/ContainerCard';
import ImageInput from '../../components/form/ImageInput';
import { useGetBranchesQuery } from '../../services/branchSlice';
import Icons from '../../components/ui/Icons';
import ResponsiveContainerCard from '../../components/ui/ResponsiveContainerCard';
import Loading from '../../components/ui/Loading';
import SelectWithLabels from '../../components/form/SelectWithLabels';
import Button from '../../components/form/Button';
import { Link } from 'react-router-dom';

const CreateAdmin = () => {

  const [postAdmin, postAdminRes] = usePostAdminMutation();
  const [activePortal, setActivePortal] = useState(PORTALS[0])
  const [activeSubPortal, setActiveSubPortal] = useState(PORTALS[0].subPortals[0])

  const branchesRes = useGetBranchesQuery();
  const [brahmvidyaAccessGranted, setBrahmvidyaAcccessGranted] = useState(false)
  const [educationAccessGranted, setEducationAcccessGranted] = useState(false)

  const activeBarClassName = "px-3 py-1 bg-secondary text-white rounded-top col"
  const inactiveBarClassName = "px-3 py-1 col"
  const consentLabel = "By checking this box I hereby give my consent for the Admin console grantal and that the user will abide by polices, terms and conditions established by the SGRS Utility Administrator. And that in any case of awry, SGRS UTILITY Administrator’s decision will be final for all settlements."

  const renderCheck = (name, option) => <Check name={name + option} label={option} containerClass={"me-3"} />


  const renderPortal = (portal) =>
    <div
      className={activePortal.name === portal.name ? activeBarClassName : inactiveBarClassName}
      onClick={() => {
        setActivePortal(portal)
        setActiveSubPortal(portal.subPortals[0])
      }}>
      {portal.name}
    </div >

  const renderSubPortal = (subPortal) => <div className={activeSubPortal.name === subPortal.name ? activeBarClassName : inactiveBarClassName} onClick={() => setActiveSubPortal(subPortal)}>{subPortal.name}</div>


  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target

    if (form.checkValidity()) {

      let body = new FormData();

      body.append("profilePicture", form["Admin Photo"].files[0]);

      body.append("name", form["Name"].value);
      body.append("sUid", form["SUID"].value);
      body.append("branchId", form["Branch"].value);
      body.append("email", form["Email"].value);
      body.append("phoneNumber", form["Phone No"].value);
      body.append("post", form["Post"].value);

      body.append("userName", form["Username"].value);
      body.append("password", form["Password"].value);
      body.append("status", form["Status"].value);

      body.append("brahmvidhyaGrantAccess", form["Brahmvidhya Grant Access"]?.checked);
      body.append("brahmvidhyaSandhyaSabha", form["Brahmvidhya Sandhya Sabha"] ? form["Brahmvidhya Sandhya Sabha"].checked : false);
      body.append("brahmvidhyaCoursebooks", form["Brahmvidhya Coursebooks"] ? form["Brahmvidhya Coursebooks"].checked : false);
      body.append("brahmvidhyaAssignments", form["Brahmvidhya Assignments"] ? form["Brahmvidhya Assignments"].checked : false);
      body.append("brahmvidhyaDownloadables", form["Brahmvidhya Downloadables"] ? form["Brahmvidhya Downloadables"].checked : false);
      body.append("brahmvidhyaTraining", form["Brahmvidhya Training"] ? form["Brahmvidhya Training"].checked : false);
      body.append("brahmvidhyaICGSPinwheel", form["Brahmvidhya ICGS Pinwheel"] ? form["Brahmvidhya ICGS Pinwheel"].checked : false);

      body.append("educationGrantAccess", form["Education Grant Access"]?.checked);
      body.append("educationCirculars", form["Education Circulars"] ? form["Education Circulars"].checked : false);
      body.append("educationSchool", form["Education School"] ? form["Education School"].checked : false);
      body.append("educationHostel", form["Education Hostel"] ? form["Education Hostel"].checked : false);
      body.append("educationEducation", form["Education Education"] ? form["Education Education"].checked : false);
      body.append("educationKanyaGurukul", form["Education Kanya Gurukul"] ? form["Education Kanya Gurukul"].checked : false);
      body.append("educationDownloadables", form["Education Downloadables"] ? form["Education Downloadables"].checked : false);
      body.append("educationArchives", form["Education Archives"] ? form["Education Archives"].checked : false);

      body.append("pin", form["Pin"].value);

      postAdmin(body).then((res) => {
        if (!res.error) {
          setBrahmvidyaAcccessGranted(false)
          setEducationAcccessGranted(false)
          form.reset()
          form.classList.remove('was-validated')
        }
      })

    }
    else form.classList.add('was-validated')


  }

  if (branchesRes.isLoading) return <Loading />
  if (branchesRes.isError) return <ApiErrorModal response={branchesRes} />

  const branches = branchesRes.data.map((branch) => ({ label: branch.id + " " + branch.branchName, value: branch.id }))


  if (branches.length === 0) {
    return (
      <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
        <h1>No Branch Created Yet</h1>
        <Link to={"/BranchManagement"} className='btn btn-primary btn-lg'>Branch Managment</Link>
      </div>
    )
  }


  return (
    <ResponsiveContainerCard
      titleBar={() =>
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className='d-flex'>
            {Icons.adminManagement("text-primary fs-1 mb-1 me-4", {})}
            <div className='fs-2 fw-bold mb-1'>
              <span className='text-danger'>Admin</span> Form
            </div>
          </div>
          <Link to={-1} className="btn btn-primary">Back</Link>
        </div>

      }
    >

      {postAdminRes.isError && <ApiErrorModal response={postAdminRes} />}
      {postAdminRes.isSuccess && <SuccessModal label="Admin Created" message="Admin successfully created." to={{ link: "/AdminManagement", text: "Go to Admin Management" }} />}

      <form onSubmit={onSubmit} noValidate>
        <Bar label={"Primary Details"}>
          <div className="d-flex flex-column flex-lg-row">

            <div className="flex-grow-1 mb-2 text-center text-lg-start">

              <ImageInput name={"Admin Photo"} />

            </div>

            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <Input name={"Name"} required />
                <Input name={"SUID"} required />
                <SelectWithLabels name={"Branch"} options={branches} />
                <Input name={"Email"} type={"email"} required />
                <Input name={"Phone No"} required />
                <Input name={"Post"} required />
                {/* <Select name={"Post"} options={POSTS} /> */}
              </div>
            </div>
          </div>
        </Bar>

        <Bar label={"Credentials"}>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
            <Input name={"Username"} required />
            <Input name={"Password"} type={"password"} required />
            <Select name={"Status"} options={["Active", "Suspended", "Blocked"]} />
          </div>
        </Bar>

        <Bar label={"Select portal"}>
          <div className="row row-cols-3 row-cols-md-3 row-cols-lg-4 g-3 fs-13">
            {PORTALS.map(renderPortal)}
          </div>
        </Bar>

        <Bar label={"Select sub portal"}>
          <div className="row row-cols-3 row-cols-md-3 row-cols-lg-4 g-3 fs-13">
            {activePortal.subPortals.map(renderSubPortal)}
          </div>
        </Bar>

        <Bar label={"Access management"} containerClass={"flex-column"}>
          <div className={activeSubPortal.name === "ICGS Brahmvidhya" ? "d-flex flex-wrap mb-3" : "d-none"}>
            <Check name={"Brahmvidhya Grant Access"} label={"Grant Access"} containerClass={"me-3"} setChecked={setBrahmvidyaAcccessGranted} />
            {brahmvidyaAccessGranted && PORTALS[0].subPortals[0].accesses.map((option) => renderCheck("Brahmvidhya ", option))}
          </div>
          <div className={activeSubPortal.name === "ICGS Education" ? "d-flex flex-wrap mb-3" : "d-none"}>
            <Check name={"Education Grant Access"} label={"Grant Access"} containerClass={"me-3"} setChecked={setEducationAcccessGranted} />
            {educationAccessGranted && PORTALS[0].subPortals[1].accesses.map((option) => renderCheck("Education ", option))}
          </div>

        </Bar>

        <Bar label={"CONSENT"} containerClass={"flex-column align-items-start"}>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  mb-3">
            <Input name={"Pin"} required />
          </div>


          <div className="row row-cols-1 align-items-center justify-content-end px-3 mb-3 g-3">
            <Check name={"consent"} label={consentLabel} containerClass={"col col-lg-10"} required />

            <Button
              className='col col-lg-2 btn btn-secondary rounded-pill text-nowrap'
              res={postAdminRes}
              loadingLabel={"Registering"}
            >
              Register Admin
            </Button>

          </div>

        </Bar>

      </form>

    </ResponsiveContainerCard>
  )

}

export default CreateAdmin
