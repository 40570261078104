import React, { useEffect, useState } from "react";
import {
  useGetMainBranchQuery,
  usePatchMainBranchMutation,
} from "../../services/mainBranchSlice";
import ApiErrorModal from "../../components/modal/ApiErrorModal";
import Icons from "../../components/ui/Icons";
import Bar from "../../components/form/Bar";
import Input from "../../components/form/Input";
import Check from "../../components/form/Check";
import Select from "../../components/form/Select";
import SuccessModal from "../../components/modal/SuccessModal";
import ResponsiveContainerCard from "../../components/ui/ResponsiveContainerCard";
import { Link, useParams } from "react-router-dom";
import Button from "../../components/form/Button";
import Loading from "../../components/ui/Loading";
import BranchDeleteModal from "../../components/modal/BranchDeleteModal";
import ImageInput from "../../components/form/ImageInput";
import Api from "../../constants/Api";
import MainBranchDeleteModal from "../../components/modal/MainBranchDeleteModal";

const EditMainBranch = () => {
  const { id } = useParams();
  const branchRes = useGetMainBranchQuery(id);
  const [patchBranch, patchBranchRes] = usePatchMainBranchMutation();
  const [formEnabled, setFormEnabled] = useState(false);

  const labelClass = "me-3 text-nowrap";
  const consentLabel =
    "By checking this box I hereby give my consent for the branch registry and that the branch will abide by polices, terms and conditions for continuing any utility service provided by the SGRS Utility Administrator. And that in any case of awry, SGRS UTILITY Administrator’s decision will be final for all settlements.";

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      let body = new FormData();

      if (form["Branch Photo"].files.length > 0)
        body.append("profilePicture", form["Branch Photo"].files[0]);

      body.append("name", form["Branch Name"].value);
      body.append("branchId", form["Branch ID"].value);
      body.append("dateEstablished", form["Date Established"].value);
      body.append("address", form["Address"].value);
      body.append("state", form["State"].value);
      body.append("country", form["Country"].value);

      body.append("externalLink", form["External Link"].value);
      body.append("status", form["Status"].value);

      body.append("consenterName", form["Consenter Name"].value);
      body.append("pin", form["Pin"].value);

      patchBranch({ id, body }).then((res) => {
        if (!res.error) {
          branchRes.refetch();
          form.classList.remove("was-validated");
        }
      });
    } else form.classList.add("was-validated");
  };

  if (branchRes.isLoading) return <Loading />;
  if (branchRes.isError) return <ApiErrorModal response={branchRes} />;

  const branch = branchRes.data;

  return (
    <ResponsiveContainerCard
      titleBar={() => (
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className="d-flex">
            {Icons.mainBranch("text-primary fs-1 mb-1 me-4", {})}
            <div className="fs-2 fw-bold mb-1">
              <span className="text-danger">
                {formEnabled ? "Edit" : "View"}
              </span>{" "}
              Branch
            </div>
          </div>

          <div className="d-flex align-items-center">
            <Check
              name={"Check to edit"}
              setChecked={setFormEnabled}
              containerClass={"me-3"}
              required
            />
            <MainBranchDeleteModal id={id} />
            <Link to={-1} className="btn btn-primary">
              Back
            </Link>
          </div>
        </div>
      )}
    >
      {patchBranchRes.isError && <ApiErrorModal response={patchBranchRes} />}
      {patchBranchRes.isSuccess && (
        <SuccessModal
          label="Branch Created"
          message="Branch successfully created."
          to={{
            link: "/MainBranchManagement",
            text: "Go to Branch Management",
          }}
        />
      )}

      <form className="" onSubmit={onSubmit} noValidate>
        <Bar label={"Primary Details"}>
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-grow-1 mb-2 text-center text-lg-start">
              <ImageInput
                name={"Branch Photo"}
                defaultValue={
                  Api.PROFILE_PICTURE_URL +
                  branch?.profilePicture?.id +
                  branch?.profilePicture?.ext
                }
                disabled={!formEnabled}
              />
            </div>

            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <Input
                  name={"Branch Name"}
                  defaultValue={branch.name}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Branch ID"}
                  placeholder={"Ex: G01"}
                  defaultValue={branch.branchId}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Date Established"}
                  type={"date"}
                  placeholder={"DD/MM/YYYY"}
                  defaultValue={
                    branch.dateEstablished
                      ? new Date(branch.dateEstablished)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  disabled={!formEnabled}
                  required
                />

                <Input
                  name={"Address"}
                  placeholder={""}
                  defaultValue={branch.address}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"State"}
                  placeholder={""}
                  defaultValue={branch.state}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Country"}
                  placeholder={""}
                  defaultValue={branch.country}
                  disabled={!formEnabled}
                  required
                />

                <Input
                  name={"External Link"}
                  defaultValue={branch.externalLink}
                  disabled={!formEnabled}
                  placeholder={"abc/anb.com"}
                />
                <Select
                  name={"Status"}
                  options={["Active", "Suspended", "Blocked"]}
                  labelClass={labelClass}
                  defaultValue={branch.status}
                  disabled={!formEnabled}
                />
              </div>
            </div>
          </div>
        </Bar>

        <Bar
          label={"CONSENT"}
          containerClass={"flex-column py-3 align-items-start"}
        >
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3  mb-3">
            <Input
              name={"Consenter Name"}
              defaultValue={branch.consenterName}
              disabled={!formEnabled}
              required
            />
            <Input name={"Pin"} disabled={!formEnabled} required />
          </div>

          <div className="row row-cols-1 align-items-center justify-content-end px-3 mb-3 g-3">
            <Check
              name={"consent"}
              label={consentLabel}
              containerClass={"col col-lg-10"}
              disabled={!formEnabled}
              required
            />

            <Button
              className="col col-lg-2 btn btn-secondary rounded-pill text-nowrap"
              disabled={!formEnabled}
              res={{
                isLoading: patchBranchRes.isLoading || branchRes.isFetching,
              }}
              loadingLabel={"Updating"}
            >
              Update Branch
            </Button>
          </div>
        </Bar>
      </form>
    </ResponsiveContainerCard>
  );
};

export default EditMainBranch;
