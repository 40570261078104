import React from 'react'
import SelectPortals from '../../components/pages/SelectPortals'

import researchImg from "../../assets/images/researchPortal/researchPortalsHeader.png"
import vrcImg from "../../assets/images/researchPortal/Rectangle 1823.png"
import gptImg from "../../assets/images/researchPortal/Rectangle 1822.png"
import wikiImg from "../../assets/images/researchPortal/Rectangle 1821.png"
import aiImg from "../../assets/images/researchPortal/Rectangle 1824.png"

const ResearchPortals = () => {
  const portals = [
    { name: "Swaminarayan VRC", img: vrcImg, color: "#002826", bgColor: "#DDFFFD", to: "./VrcPortal" },
    { name: "Swaminarayan GPT", img: gptImg, color: "#2C2866", bgColor: "#DDDAFF", to: "./GptPortal" },
    { name: "Swaminarayan Wikipedia", img: wikiImg, color: "#FFCF2E", bgColor: "#FFE9A1", to: "./WikiPortal" },
    { name: "AI Brahm", img: aiImg, color: "#FEA8E8", bgColor: "#FFD8F5", to: "./AiBrahmPortal" },
  ]

  return <SelectPortals headImg={researchImg} portals={portals} />
}
 
export default ResearchPortals