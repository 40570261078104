import React from 'react'
import backImg from "../assets/images/signInBack.png"
import amritMahotsavImg from "../assets/images/amritMahotsav.png"
import SignInForm from '../components/pages/SignInForm'

const SignIn = () => {

  const amritMahotsavClassName = "d-none d-lg-block position-absolute top-0 bottom-0 start-0 end-50"
  const formLgContainerClassName = "d-none d-lg-block position-absolute top-0 bottom-0 start-50 end-0 py-3"
  const formLgClassName = "bg-white h-100 w-75 ms-auto rounded-start-5 overflow-hidden shadow"
  const formSmContainerClassName = "d-flex d-lg-none position-absolute top-0 bottom-0 start-0 end-0 px-3 align-items-center"
  const formSmClassName = "bg-white w-100 ms-auto rounded-5 overflow-hidden shadow"


  return (
    <div className='h-100 position-relative'>

      <img src={backImg} className='w-100 h-100 object-fit-cover' alt='' />

      <div className={amritMahotsavClassName}>
        <img src={amritMahotsavImg} alt="" className='object-fit-contain mt-5 ms-5' style={{ height: 200 }} />
      </div>

      <div className={formLgContainerClassName}>
        <div className={formLgClassName}>
          <SignInForm />
        </div>
      </div>

      <div className={formSmContainerClassName}>
        <div className={formSmClassName}>
          <SignInForm />
        </div>
      </div>

    </div>
  )
}

export default SignIn