import React from 'react'

const SelectWithLabels = ({ options, name, labelClass, containerClass, inputClass, setValue, ...attributes }) => {

    const containerClassName = "d-flex align-items-center " + (containerClass ? containerClass : "")
    const labelClassName = "fw-bold fs-13 text-nowrap me-3 " + (labelClass ? labelClass : "")
    const inputSelectClassName = "form-select form-select-sm rounded-pill bg-info shadow-sm " + (inputClass ? inputClass : "")

    const onChange = (e) => setValue && setValue(e.target.value)

    return (
        <div className={containerClassName}>
            <label htmlFor={name} className={labelClassName}>{name}</label>
            <select
                className={inputSelectClassName}
                name={name}
                id={name}
                onChange={onChange}
                {...attributes}
            >
                {Object.values(options).map((option) => <option value={option.value}>{option.label}</option>)}
            </select>
        </div>

    )
}

export default SelectWithLabels



