import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        signIn: builder.mutation({ query: (body) => ({ url: `signIn`, method: 'POST', body }) }),
        signOut: builder.mutation({ query: (body) => ({ url: `signOut`, method: 'POST', body }) }),
        generateToken: builder.mutation({ query: (body) => ({ url: `api/generate-token`, method: 'POST', body }) }),
        refreshToken: builder.mutation({ query: (body) => ({ url: `api/refresh-token`, method: 'POST', body }) }),
    })
})

export const {
    useSignInMutation,
    useSignOutMutation,
    useGenerateTokenMutation,
    useRefreshTokenMutation
} = extendedApiSlice
