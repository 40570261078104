import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Icons from '../../components/ui/Icons'
import Input from '../../components/form/Input'
import { useCloseSupportMutation, useGetSupportTicketsQuery } from '../../services/supportSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modal/ApiErrorModal'
import ResponsiveContainerCard from '../../components/ui/ResponsiveContainerCard'
import SupportChat from './SupportChat'
import supportLogo from "../../assets/images/supportLogo.png"
import SupportChatOffCanvas from './SupportChatOffCanvas'

const Tickets = () => {

    const supportTicketsRes = useGetSupportTicketsQuery();
    const navigate = useNavigate();

    const [closeSupport, closeSupportResponse] = useCloseSupportMutation()
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [search, setSearch] = useState("");

    const handleToggle = (item) => {
        setSelectedTicket(item.id)

    }



    useEffect(() => {

        if (selectedTicket) {
            const btn = document.getElementById("offcanvas" + selectedTicket + "Button")
            console.log(btn)
            if (btn) btn.click()
        }

    }, [selectedTicket])

    if (supportTicketsRes.isLoading) return <Loading />
    if (supportTicketsRes.isError) return <ApiErrorModal response={supportTicketsRes} />


    return (

        <div className=' m-3 d-flex hide-scroll pt-3' style={{ height: '90vh' }} >

            <div className=' flex-grow-1 mx-lg-3 overflow-hidden '>

                <div className="d-flex align-items-center justify-content-between rounded-4 bg-primary p-3 border-bottom">
                    <h2 className='text-white fw-bold'>Tickets</h2>
                    <div>{search}
                        <Input placeholder={"Search"} value={search} onChange={e => setSearch(e.target.value)} />
                    </div>
                </div>

                <div className=' overflow-y-auto h-100'>

                    {supportTicketsRes.data
                        .filter((i) => {
                            if (search.length > 0) {
                                if (search == i.id) return true
                                else if (i.topic.indexOf(search) != -1) return true
                            }
                            else return true
                        })
                        .sort((a, b) => {
                            // Assuming CLOSED status means resolved and other statuses mean unresolved
                            const statusA = a.status === "CLOSED" ? 1 : 2;
                            const statusB = b.status === "CLOSED" ? 1 : 2;

                            if (statusA !== statusB) {
                                return statusB - statusA; // Sort by status first (resolved tickets come first)
                            }

                            // If statuses are the same, sort by createdAt date
                            const dateA = new Date(a.createdAt);
                            const dateB = new Date(b.createdAt);
                            return dateA - dateB;
                        })
                        .map((item) =>
                            <div className="">
                                <div className='bg-white rounded-4 my-3 shadow container m-0 ' key={item.id} onClick={() => handleToggle(item)}>

                                    <div className="row flex-nowrap overflow-x-auto">
                                        <div className="col">
                                            <div className='p-3 border-end  '>
                                                <h6 className='fw-bold'>ID</h6>
                                                <p className='fs-13 text-muted'>{item.id}</p>
                                                {/* <p className='fs-13 text-muted'>{new Date(item.createdAt).toDateString()}</p> */}
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className='py-3 border-end '>
                                                <h6 className='fw-bold'>
                                                    <span className={"d-lg-none " + (item.status === 'Created' ? 'text-danger' : 'text-success')}>&#9679;</span>Topic
                                                </h6>                                                {/* <p className='fs-13 text-muted'>{item.topic.length > 15 ? item.topic.substring(0, 14) + "..." : item.topic}</p> */}
                                                <p className='fs-13 text-muted overflow-x-hidden text-nowrap me-2'>{item.topic}</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className='py-3 border-lg-end '>
                                                <h6 className='fw-bold'>Portal</h6>
                                                <p className='fs-13 text-muted'>{item.portal}</p>
                                            </div>
                                        </div>
                                        <div className="col d-none d-lg-block">
                                            <div className='py-3 d-flex align-items-center  h-100 '>
                                                {
                                                    (item.status == 'UnResolved') ?
                                                        <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "red" }}>Unresolve</div>
                                                        :
                                                        <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "#508D02" }}>Resolved</div>

                                                }
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className='d-flex align-items-center p-4' style={{ width: 80 }}>
<div  className='btn p-0'>{Icons.message()}</div>
</div> */}

                                </div>
                            </div>
                        )}
                </div>


            </div>

            <div className='d-lg-none'>
                {
                    selectedTicket &&
                    <SupportChatOffCanvas id={selectedTicket} res={supportTicketsRes} closeSupport={closeSupport} />
                }
            </div>

            <div className='col-md-5 ms-3 card shadow rounded-5 overflow-hidden d-none d-lg-block '>
                {
                    selectedTicket &&
                    <SupportChat id={selectedTicket} res={supportTicketsRes} closeSupport={closeSupport} />
                }
                {
                    !selectedTicket && <div className='bg-white rounded-4 '>
                        <div className='bg-light py-3 border-bottom rounded w-100'>
                            <h4 className='fw-bold mx-3 '><span className='text-danger'>Resolve</span> Tickets</h4>
                        </div>
                        <div className='p-5' style={{ height: 400 }}>
                            <img src={supportLogo} className='img-fluid' style={{ maxWidth: '100%' }} />
                            <div className='text-center mt-4'>
                                <h5>Integrated Ticket resolving section for SGRS Utility Applications</h5>
                                <p className='mt-4 opacity-75'>Prioritize the resolution of unresolved tickets for enhanced service provision.
                                    Tickets resolved by super administrator are tagged as message from Super Administrator.</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default Tickets