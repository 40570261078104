import React, { useEffect, useState } from 'react'
import Input from '../form/Input'
import { useDeleteReminderMutation, useGetReminderQuery, usePatchReminderMutation } from '../../services/reminderSlice';
import Select from '../form/Select';
import Button from '../form/Button';
import { getApiErrorMessage } from '../../utility/errorMessages';
import SuccessModal from './SuccessModal';
import Loading from '../ui/Loading';
import ApiErrorModal from './ApiErrorModal';
import Check from '../form/Check';
import Icons from '../ui/Icons';
import FormattedInput from '../form/FormattedInput';

const ViewEditReminderModal = ({ refetch, id, subPortal }) => {

    const modalId = "viewEditReminderModal" + id
    const formattedInputName = "formattedInput" + id
    const res = useGetReminderQuery(id)
    const [editorContent, setEditorContent] = useState('');
    const [patchReminder, patchReminderRes] = usePatchReminderMutation();
    const [deleteReminder, deleteReminderRes] = useDeleteReminderMutation();
    const [formEnabled, setFormEnabled] = useState(false)

    const statusOptions = ["Created", "Skipped Point 1", "Skipped Point 2", "Done"]

    const reccuringOptions = ["ONCE", "MONTLY", "YEARLY"]

    const handleEditorContentChange = (content) => setEditorContent(content)

    const onDelete = () => {
        deleteReminder(id).then((res) => {
            if (!res.error) {
                refetch()
                document.getElementById(modalId + "Button").click()
            }
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {
            const body = {
                title: form['Reminder Title'].value,
                memo: editorContent,
                pointOne: form['Reminder Point 1'].value,
                pointTwo: form['Reminder Point 2'].value,
                endPoint: form['Task End Point'].value,
                portal: form['Portal'].value,
                status: form['Status'].value,
                reccuring: form['Reccuring'].value,

            };

            patchReminder({ id, body }).then((res) => {
                if (!res.error) {
                    refetch()
                    form.classList.remove('was-validated')
                    document.getElementById(modalId + "Button").click()
                }
            })

        }
        else form.classList.add('was-validated')

    }

    useEffect(() => {
        if (res.isSuccess && res.data) {
            setEditorContent(res.data.memo)
        }
    }, [res.isSuccess, res.data])

    if (res.isLoading) return <Loading />
    if (res.isError) return <ApiErrorModal response={res} />

    const rem = res.data

    return (
        <>

            {patchReminderRes.isSuccess && <SuccessModal message={"Reminder successfullly updated."} />}
            {
                subPortal
                    ?
                    <div style={{ color: "#01519B" }} id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Update {Icons.updateCalendar("fs-6", {})}</div>
                    :
                    <div className="btn btn-secondary btn-sm text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>View</div>
            }

            <div className="modal fade text-dark" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={onSubmit} noValidate>
                    <div className="modal-content bg-white rounded-4">
                        <div className="rounded-4">
                            <div className="modal-header py-0 border-0 position-relative">
                                <div className='px-5 bg-primary rounded-top-5 rounded-end-5 text-white py-2 position-absolute start-0'>Important Reminder Memos</div>
                            </div>
                            <div className="modal-body text-dark p-5">
                                <h5 className="fw-bold mb-3">Primary Details</h5>
                                <Input name={"Reminder Title"} containerClass={'col mb-4'} defaultValue={rem.title} disabled={!formEnabled} required />

                                <FormattedInput label={"Reminder Memo"} name={formattedInputName} defaultValue={res.data.memo} disabled={!formEnabled} />


                                <h5 className="fw-bold mt-5 mb-3">Reminder Date</h5>
                                <div className='row row-cols-2 g-3'>
                                    <Input name={"Reminder Point 1"} type={"date"} defaultValue={rem.pointOne ? new Date(rem.pointOne).toISOString().split('T')[0] : ''} disabled={!formEnabled} required />
                                    <Input name={"Reminder Point 2"} type={"date"} defaultValue={rem.pointTwo ? new Date(rem.pointTwo).toISOString().split('T')[0] : ''} disabled={!formEnabled} required />
                                    <Input name={"Task End Point"} type={"date"} defaultValue={rem.endPoint ? new Date(rem.endPoint).toISOString().split('T')[0] : ''} disabled={!formEnabled} required />
                                    <Select name={"Portal"} options={["Super Admin", "ICGS Brahmvidya", "ICGS Education"]} defaultValue={rem.portal} disabled={!formEnabled} required />
                                    <Select name={"Status"} options={statusOptions} defaultValue={rem.status} disabled={!formEnabled} required />
                                    <Select name={"Reccuring"} options={reccuringOptions} defaultValue={rem.reccuring} disabled={!formEnabled} required />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-end border-0">
                                {patchReminderRes.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {getApiErrorMessage(patchReminderRes).label}: {getApiErrorMessage(patchReminderRes).message}
                                    </div>
                                }

                                <Check name={"Check to edit"} setChecked={setFormEnabled} containerClass={"me-3"} required />

                                <Button
                                    className="btn btn-danger text-white rounded-pill px-4 shadow-sm"
                                    loadingLabel="Deleting"
                                    res={deleteReminderRes}
                                    disabled={!formEnabled}
                                    type="button"
                                    onClick={onDelete}
                                >
                                    Delete
                                </Button>

                                <Button
                                    className="btn btn-secondary rounded-pill px-4 shadow-sm"
                                    loadingLabel="Updating"
                                    res={patchReminderRes}
                                    disabled={!formEnabled}
                                >
                                    Update
                                </Button>

                            </div>
                        </div>
                    </div>
                </form >
            </div >

        </>
    )
}

export default ViewEditReminderModal