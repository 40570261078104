import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { apiSlice } from './apiSlice'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import authReducer from './authSlice'

const key = 'icgs-super-admin3'
const persistConfig = { key, storage };
const persistedReducer = persistReducer(persistConfig, authReducer)
const reducer = combineReducers({ auth: persistedReducer, [apiSlice.reducerPath]: apiSlice.reducer })
export const store = configureStore({ reducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware) })

setupListeners(store.dispatch)