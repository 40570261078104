import React, { useEffect, useState } from 'react'
import Icons from '../../components/ui/Icons'
import { Link, useNavigate } from 'react-router-dom'
import { useAdminInsightsQuery } from '../../services/insightSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modal/ApiErrorModal'
import { useGetRemindersQuery } from '../../services/reminderSlice'
import PostReminderModal from '../../components/modal/PostReminderModal'
import ViewEditReminderModal from '../../components/modal/ViewEditReminderModal'
import { transformDateOnly } from '../../utility/date'
import { useGetAdminsQuery, usePatchToggleEducationMutation } from '../../services/adminSlice'
import SuccessModal from '../../components/modal/SuccessModal'
import { useGetUsersQuery } from '../../services/userSlice'
import { useGetInfosQuery } from '../../services/infoSlice'
import ViewEditInfoModal from '../../components/modal/ViewEditInfoModal'
import { useGenerateTokenMutation } from '../../services/authSlice'
import Api from '../../constants/Api'

const COLOR = {
    BLUE: "#01519B",
    RED: "#A01F1F",
    GREEN: "#1FA024",
    VIOLET: "#79008D"
}

const EducationPortal = () => {

    const navigate = useNavigate()
    const res = useAdminInsightsQuery()
    const remindersRes = useGetRemindersQuery()

    const getAdmins = useGetAdminsQuery()
    const getUsers = useGetUsersQuery()

    const getInfo = useGetInfosQuery();

    const [toggleEducation, toggleEducationResponse] = usePatchToggleEducationMutation()
    const [generateToken, generateTokenRes] = useGenerateTokenMutation();


    const [userToken, setUserToken] = useState('');
    const [adminToken, setAdminToken] = useState('');




    const handleToggleChange = (isEducationOpen) => {
        toggleEducation({ isEducationOpen });
    };

    useEffect(() => {
        // Make an API call to generate the token for the user

        const filteredAdmin = getAdmins?.data?.filter((item) => (item.id === 1))
        const filteredUser = getUsers?.data?.filter((item) => (item.id === 1))


        const fetchUserToken = async () => {
            try {
                const response = await generateToken({ id: filteredUser[0].id, type: 'USER' })
                setUserToken(response.data);
            } catch (error) {
                console.error('Error fetching user token:', error);
            }
        };

        const fetchAdminToken = async () => {
            try {
                const response = await generateToken({ id: filteredAdmin[0].id, type: 'ADMIN' })
                setAdminToken(response.data);
            } catch (error) {
                console.error('Error fetching user token:', error);
            }
        };

        fetchUserToken();
        fetchAdminToken();



    }, [getUsers.isSuccess, getAdmins.isSuccess]);


    const pillBtnClassName = "btn rounded-pill mx-2 px-3 py-1 text-white mb-0"

    if (res.isLoading || remindersRes.isLoading || getInfo.isLoading || getAdmins.isLoading || getUsers.isLoading) return <Loading />
    if (res.isError) return <ApiErrorModal response={res} />
    if (remindersRes.isError) return <ApiErrorModal response={remindersRes} />
    if (getInfo.isError) return <ApiErrorModal response={getInfo} />
    if (getAdmins.isError) return <ApiErrorModal response={getAdmins} />
    if (getUsers.isError) return <ApiErrorModal response={getUsers} />

    return (
        <div className=" p-4 h-100 ">
            {
                (toggleEducationResponse.isSuccess) && <SuccessModal message={toggleEducationResponse.data.message} />
            }
            <div className="card p-3 bg-white bg-opacity-25 border-0 shadow rounded-3 h-100" >

                <div className="d-flex align-items-center mb-5">
                    <div className='fs-3 fw-bold text-blue me-auto'><span className='text-danger'>Education</span> Portal</div>
                    {
                        (userToken) ?
                            <Link className={pillBtnClassName} style={{ backgroundColor: COLOR.BLUE }} to={`${Api.EDUCATION_USER_URL}token/${userToken}`} target="_blank">User Interface</Link>
                            :
                            <Link className={pillBtnClassName} style={{ backgroundColor: COLOR.BLUE }} to={Api.EDUCATION_USER_URL} target="_blank">User Interface</Link>

                    }

                    {
                        (adminToken) ?
                            < Link className={pillBtnClassName} style={{ backgroundColor: COLOR.RED }} to={`${Api.EDUCATION_ADMIN_URL}token/${adminToken}`} target="_blank">Admin Console</Link>
                            :
                            < Link className={pillBtnClassName} style={{ backgroundColor: COLOR.RED }} to={Api.EDUCATION_ADMIN_URL} target="_blank">Admin Console</Link>

                    }


                    <Link className={pillBtnClassName} style={{ backgroundColor: COLOR.GREEN }} to={Api.EDUCATION_BACKEND_URL} target="_blank">Backend Console</Link>
                </div>

                <div className="row gy-5 gx-3 mb-5">

                    <div className="col-7 d-flex flex-column align-items-center">
                        <div className="card d-flex w-100 text-center rounded-5 overflow-hidden shadow ">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-3 py-2'>Portal User Summary</span>
                            </div>

                            <div className='d-flex mt-4 '>
                                <div className='flex-grow-1'>
                                    <div className='display-3'>{res.data.educationAdminCount}</div>
                                    <div className='mb-2'>Admin users</div>
                                    <div className='text-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/AdminManagement/portal/Education')}>Manage Admins</div>
                                </div>

                                <div className='flex-grow-1'>
                                    <div className='display-3'>{res.data.educationUserCount}</div>
                                    <div className='mb-2'>Utility users</div>
                                    <div className='text-white border-start border-end border-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/UserManagement/portal/Education')}>Manage Users</div>
                                </div>

                                <div className='flex-grow-1'>
                                    <div className='display-3'>{res.data.branchCount}</div>
                                    <div className='mb-2'>Branches</div>
                                    <div className='text-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/BranchManagement')}>Manage Branches</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-5 d-flex flex-column align-items-start">
                        {/* < div className='rounded-end-pill rounded-top-pill bg-secondary text-white py-2 px-5' style={{ marginBottom: -20, zIndex: 5 }}>Service Activation</div> */}
                        <div className="flex-grow-1 card  w-100 text-center  rounded-5  overflow-hidden shadow">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-3 py-2'>Service Activation</span>
                            </div>

                            <div className="d-flex gap-5 mt-4 px-3">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        defaultChecked={res?.data?.serviceActivation?.isEducationOpen}
                                        onChange={() => handleToggleChange(!res.data?.serviceActivation?.isEducationOpen)}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                                        Web Portal
                                    </label>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="row flex-grow-1">
                    <div className="col-7  d-flex flex-column align-items-center">
                        <div className="card w-100 h-100 text-center rounded-5 overflow-hidden shadow">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-4 py-2'>Portal Status Report</span>
                            </div>

                            <div className="p-3 pt-1 hide-scroll">
                                <div className="d-flex align-items-center justify-content-end mb-3">
                                    <PostReminderModal refetch={remindersRes.refetch} subPortal />
                                </div>

                                <div className="overflow-auto h-100" style={{ maxHeight: 310 }}>
                                    {remindersRes.data.filter(i => i.portal === "ICGS Education").map(item =>
                                        <div className="d-flex justify-content-between fs-13">
                                            <div>{item.title}</div>
                                            <div style={{ color: COLOR.GREEN }}>{transformDateOnly(item.pointOne)}</div>
                                            <ViewEditReminderModal refetch={remindersRes.refetch} id={item.id} subPortal />
                                        </div>
                                    )}

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-5 d-flex flex-column align-items-start">

                        {/* <div className='rounded-end-pill rounded-top-pill bg-secondary text-white py-2 px-5' style={{ marginBottom: -20, zIndex: 5 }}>Critical Information</div> */}

                        <div className="flex-grow-1 card w-100 text-start overflow-hidden shadow rounded-5 hide-scroll" >
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-4 py-2'>Critical Information</span>
                            </div>
                            <div className='text-end px-4'>
                                {
                                    (getInfo.data.filter((i) => i.type === 'Education').length > 0) ?

                                        <ViewEditInfoModal refetch={getInfo.refetch} id={getInfo.data.filter((i) => i.type === 'Education')[0].id} subPortal={'Education'} isCreate={false} />
                                        :

                                        <ViewEditInfoModal refetch={getInfo.refetch} id={4} subPortal={'Education'} isCreate={true} />
                                    // <div className='rounded-start-pill rounded-bottom-pill bg-primary text-white py-2 px-5 align-self-end' style={{ marginTop: -20, zIndex: 5 }}>Create Information</div>

                                }
                            </div>
                            <div className="px-4 overflow-auto" style={{ maxHeight: 310 }}>
                                {
                                    getInfo.data
                                        .filter((i) => i.type === 'Education')
                                        .map((i, index) => (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: i.content }} />
                                        ))
                                }

                                {/* <div className='rounded-start-pill rounded-bottom-pill bg-primary text-white py-2 px-5 align-self-end' style={{ marginTop: -20, zIndex: 5 }}>Edit Information</div> */}



                            </div>

                        </div>
                    </div>

                </div>


            </div >
        </div >
    )


}

export default EducationPortal