import React from 'react'
import ResponsiveContainerCard from '../../components/ui/ResponsiveContainerCard'
import { Link } from 'react-router-dom'
import Icons from '../../components/ui/Icons'
import { useGetRemindersQuery } from '../../services/reminderSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modal/ApiErrorModal'
import PostReminderModal from '../../components/modal/PostReminderModal'
import { transformDateOnly } from '../../utility/date'
import ViewEditReminderModal from '../../components/modal/ViewEditReminderModal'

const ReminderManagement = () => {

    const res = useGetRemindersQuery()
    const backgroundColor = '#04143E'
    const headers = ["ID", "Title", "Point 1", "Point 2", "End Point", "Portal", "Reccuring", "Status", "Actions"]

    const getTableHead = () =>
        <thead>
            <tr className='text-nowrap '>
                {headers.map((header) => <th key={header} className='text-white sticky-top bg-primary' >{header}</th>)}
            </tr>
        </thead>

    const getStatusTag = (status) => {
        const commonClass = "rounded-pill text-white fw-bold px-3 "
        switch (status) {
            // case "Suspended": return <span className={commonClass + 'bg-warning'}>{status}</span>
            // case "Blocked": return <span className={commonClass + 'bg-danger'}>{status}</span>
            default: return <span className={commonClass + 'bg-success'}>{status}</span>
        }
    }

    if (res.isLoading) return <Loading />
    if (res.isError) return <ApiErrorModal response={res} />

    return (
        <ResponsiveContainerCard
            titleBar={() =>
                // <div className="d-flex flex-column flex-lg-row align-items-center justify-content-around py-3 px-4">
                //     {Icons.updateCalendar("text-primary fs-1 mb-1", {})}
                //     <div className='fs-2 fw-bold mb-1'>
                //         <span className='text-danger'>Reminder</span> Management
                //     </div>
                //     <PostReminderModal refetch={res.refetch} />
                // </div>
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
                    <div className='d-flex'>
                        {Icons.updateCalendar("text-primary fs-1 mb-1 me-4", {})}
                        <div className='fs-2 fw-bold mb-1'>
                            <span className='text-danger'>Reminder</span> Management
                        </div>
                    </div>
                    <PostReminderModal refetch={res.refetch} />
                </div>
            }
        >
            <table className="table">
                {getTableHead()}
                <tbody>
                    {
                        res?.data?.map((data, i) => (
                            <tr key={i} className='text-nowrap '>
                                <td>{data.id}</td>
                                <td>{data.title}</td>
                                <td>{transformDateOnly(data.pointOne)}</td>
                                <td>{transformDateOnly(data.pointTwo)}</td>
                                <td>{transformDateOnly(data.endPoint)}</td>
                                <td>{data.portal}</td>
                                <td>{data.reccuring}</td>
                                <td>{getStatusTag(data.status)}</td>
                                <td>
                                    <ViewEditReminderModal refetch={res.refetch} id={data.id} />
                                    {/* <Link to={`/AdminManagement/${data.id}`} className='btn btn-primary rounded-pill btn-sm px-3' >View Details</Link> */}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </ResponsiveContainerCard>
    )
}

export default ReminderManagement