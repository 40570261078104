import React from 'react'
import Input from '../form/Input'
import Icons from '../ui/Icons'
import ApiErrorModal from './ApiErrorModal'
import SuccessModal from './SuccessModal'

const CredManagerModal = ({ updatePin, updatePinResponse, updatePassword, updatePasswordResponse }) => {

    const handlePinSubmit = (e) => {
        e.preventDefault()
        const form = e.target;

        const currentPin = e.target["Current pin :"].value;
        const newPin = e.target["New pin :"].value;

        updatePin({ currentPin: currentPin, newPin: newPin })
            .then((res) => {
                if (!res.error) {
                    form.reset()
                }
            });
    }

    const handlePasswordSubmit = (e) => {
        e.preventDefault()
        const form = e.target;

        const email = e.target["Current Login ID :"].value;
        const currentPassword = e.target["Current Password :"].value;
        const newPassword = e.target["New Password :"].value;

        updatePassword({ email: email, currentPassword: currentPassword, newPassword: newPassword })
            .then((res) => {
                if (!res.error) {
                    form.reset()
                }
            });;
    }

    return (
        <div className="modal fade text-dark" id="credManagerModal" tabIndex="-1" aria-labelledby="credManagerModalLabel" aria-hidden="true">

            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content bg-white rounded-4">
                    <div className="rounded-4 ">

                        <div className="modal-header py-0 border-0">
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                 */}
                            <div className='text-center'>
                                {Icons.credUtility('fs-1')}
                                <p className='fs-13'>SGRS Utility</p>
                            </div>
                            <div className='text-center mx-3 rounded-bottom-5 shadow px-5'>
                                <div className='bg-primary p-3 rounded-bottom-5 text-white'>Secure Credentials Manager</div>
                                <p className='fs-13 fw-bold my-3'>“Your secret place to Protect your account and enhance security”</p>
                            </div>
                            <div className='text-center'>
                                {Icons.credAuth('fs-1')}
                                <p className='fs-13'>Authentication</p>
                            </div>

                        </div>
                        <div className="modal-body text-dark p-5">
                            <div className='d-flex flex-row'>

                                <div className='border-end p-4'>
                                    <form onSubmit={handlePinSubmit}>
                                        {(updatePinResponse.isSuccess) && <p className='fs-11 text-success fw-bold text-uppercase'>Pin Changed Successfully.</p>}
                                        {(updatePinResponse.isError) && <p className='fs-11 text-danger fw-bold text-uppercase'>{updatePinResponse.error.data.error}.</p>}
                                        <div className="btn btn-secondary btn-sm rounded-3 text-white mb-4">Security Pin</div>
                                        <Input name={"Current pin :"} containerClass={'col mb-4'} />
                                        <Input name={"New pin :"} containerClass={'col mb-4'} />
                                        <button className="btn btn-secondary btn-sm rounded-pill px-4 text-white mb-3">Change Security Pin</button>
                                        <p className='fs-11'>By changing the pin it means your security pin will be changed throughout the App.</p>
                                    </form>
                                </div>

                                <div className='border-end p-4'>
                                    <div className="btn btn-secondary btn-sm rounded-3 text-white mb-4">2FA Validation</div>
                                    <Input name={"Current E-mail :"} containerClass={'col mb-4'} />
                                    <Input name={"2FA Code :"} containerClass={'col mb-4'} />
                                    <p className='fs-11'>For enhanced security it is best to keep 2FA on.</p>
                                    <div className="btn btn-secondary btn-sm rounded-pill px-4 text-white my-3">Verify Email</div>
                                    <p className='fs-11'>By changing the 2FA email , you should consent that all the 2FA Codes will be forwarded to the new email.</p>
                                </div>

                                <div className='p-4'>
                                    <div className="mb-5"></div>
                                    <Input name={"New E-mail :"} containerClass={'col mb-4'} />
                                    <Input name={"2FA Code :"} containerClass={'col mb-4'} />
                                    <p className='fs-11'>For enhanced security it is best to keep 2FA on.</p>
                                    <div className="btn btn-secondary btn-sm rounded-pill px-4 text-white my-3">Change 2FA Email</div>
                                    <p className='fs-11'>By changing the 2FA email , you should consent that all the 2FA Codes will be forwarded to the new email.</p>
                                </div>

                            </div>

                            <div className='p-4 w-50'>
                                <form onSubmit={handlePasswordSubmit}>
                                    {(updatePasswordResponse.isSuccess) && <p className='fs-11 text-success fw-bold text-uppercase'>Password Changed Successfully.</p>}
                                    {(updatePasswordResponse.isError) && <p className='fs-11 text-danger fw-bold text-uppercase'>{updatePasswordResponse.error.data.error}</p>}
                                    <div className="btn btn-secondary btn-sm rounded-3 text-white mb-4">Login Credntials</div>
                                    <Input name={"Current Login ID :"} containerClass={'col mb-4'} />
                                    <Input name={"Current Password :"} containerClass={'col mb-4'} />
                                    <Input name={"New Password :"} containerClass={'col mb-4'} />
                                    <p className='fs-11'>By changing the Login ID it means you will have to input new ID always ahead for signing in the App.</p>
                                    <button className="btn btn-secondary btn-sm rounded-pill px-4 text-white my-3">Change Password</button>
                                    <p className='fs-11'>By changing the password it means the password will be changed for your login.</p>
                                </form>
                            </div>

                        </div>
                        <div className="modal-footer justify-content-end border-0">
                            <button type="button" className="btn btn-secondary rounded-pill px-4 shadow-sm" data-bs-dismiss="modal">Done</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CredManagerModal