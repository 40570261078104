import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInfos: builder.query({ query: () => `/criticalInfo`, keepUnusedDataFor: 0 }),
        getInfo: builder.query({ query: (id) => `/criticalInfo?id=${id}`, keepUnusedDataFor: 0 }),
        postInfo: builder.mutation({ query: (body) => ({ url: `/criticalInfo`, method: 'POST', body }) }),
        patchInfo: builder.mutation({ query: ({ id, body }) => ({ url: `/criticalInfo?id=${id}`, method: 'PATCH', body }) }),
        deleteInfo: builder.mutation({ query: (id) => ({ url: `/criticalInfo?id=${id}`, method: 'DELETE' }) }),
    }),
})


export const { useGetInfoQuery, useGetInfosQuery, usePostInfoMutation, usePatchInfoMutation, useDeleteInfoMutation } = extendedApiSlice
