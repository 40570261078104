import React, { useEffect, useRef, useState } from 'react'

const ImageInput = ({ name, labelName, labelClass, containerClass, defaultValue, ...attributes }) => {

    const [file, setFile] = useState(null)
    const fileInputRef = useRef(null);

    const containerClassName = "d-flex flex-column align-items- fs-13 " + (containerClass ? containerClass : "")
    const labelClassName = "card fw-bold px-3 py-1 rounded-pill mb-" + (labelClass ? labelClass : "")


    const onChange = (e) => setFile(e.target.files[0])



    useEffect(() => {
        const inputElement = fileInputRef.current;
        const form = inputElement?.form;

        if (form) {
            const handleReset = () => setFile(null);
            form.addEventListener('reset', handleReset);

            // Cleanup the event listener
            return () => {
                form.removeEventListener('reset', handleReset);
            };
        }
    }, []);

    return (
        <div className={containerClassName}>
            {
                file ?
                    <label htmlFor={name}>
                        <img
                            src={URL.createObjectURL(file)} className='object-fit-cover rounded-circle border border-2 border-dark' alt="" style={{ height: 50, width: 50 }} />
                    </label>
                    :
                    defaultValue ?
                        <label htmlFor={name}>
                            <img
                                src={defaultValue} className='object-fit-cover rounded-circle border border-2 border-dark' alt="" style={{ height: 50, width: 50 }} />
                        </label>
                        :
                        <label htmlFor={name} className={labelClassName}>{labelName ? labelName : "Select Image"}</label>
            }

            <input
                type={"file"}
                className={"d-none"}
                id={name}
                name={name}
                ref={fileInputRef}
                onChange={onChange}
                {...attributes}
            />
            <div className='invalid-feedback'>*Select Image</div>
            <div className='fw-bold mt-2'>{name}</div>
            <div className='fs-11'>This will be displayed on user Profile.</div>
        </div>
    )
}

export default ImageInput
