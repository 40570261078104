import React from 'react'

const ContainerCard = ({ children }) => {

    const containerClassName = "p-3 p-lg-5 h-100 overflow-hidden hide-scroll"
    const cardClassName = "card rounded-5 shadow-lg h-100 overflow-hidden hide-scroll"

    return (
        <div className={containerClassName} ><div className={cardClassName}>{children}</div></div>
    )
}

export default ContainerCard