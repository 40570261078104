import React from 'react'
import Icons from '../../components/ui/Icons'
import { useNavigate } from 'react-router-dom'
import ViewEditInfoModal from '../../components/modal/ViewEditInfoModal'
import { useGetInfosQuery } from '../../services/infoSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modal/ApiErrorModal'

const COLOR = {
    BLUE: "#01519B",
    RED: "#A01F1F",
    GREEN: "#1FA024",
    VIOLET: "#79008D"
}

const ELearningPortal = () => {

    const navigate = useNavigate()



    const getInfo = useGetInfosQuery();

    const pillBtnClassName = "btn rounded-pill mx-2 px-3 py-1 text-white mb-0"
    if (getInfo.isLoading) return <Loading />
    if (getInfo.isError) return <ApiErrorModal response={getInfo} />

    return (
        <div className=" p-4 h-100 ">

            <div className="card p-3 bg-white bg-opacity-25 border-0 shadow rounded-3 h-100" >

                <div className="d-flex align-items-center mb-5">
                    <div className='fs-3 fw-bold text-blue me-auto'><span className='text-danger'>E-Learning</span> Portal</div>
                    <div className={pillBtnClassName} style={{ backgroundColor: COLOR.BLUE }}>User Interface</div>
                    <div className={pillBtnClassName} style={{ backgroundColor: COLOR.RED }}>Admin Console</div>
                    <div className={pillBtnClassName} style={{ backgroundColor: COLOR.GREEN }}>Backend Console</div>
                </div>

                <div className="row gy-5 gx-3 mb-5">

                    <div className="col-7 d-flex flex-column align-items-center">
                        <div className="card d-flex  w-100 text-center rounded-5 overflow-hidden shadow ">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-4 py-2'>Portal User Summary</span>
                            </div>

                            <div className='d-flex mt-4 '>
                                <div className='flex-grow-1'>
                                    <div className='display-3'>20</div>
                                    <div className='mb-2'>Admin users</div>
                                    <div className='text-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/AdminManagement')}>Manage Admins</div>
                                </div>

                                <div className='flex-grow-1'>
                                    <div className='display-3'>20</div>
                                    <div className='mb-2'>Utility users</div>
                                    <div className='text-white border-start border-end border-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/UserManagement')}>Manage Users</div>
                                </div>

                                <div className='flex-grow-1'>
                                    <div className='display-3'>20</div>
                                    <div className='mb-2'>Branches</div>
                                    <div className='text-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/BranchManagement')}>Manage Branches</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-5 d-flex flex-column align-items-start">
                        {/* < div className='rounded-end-pill rounded-top-pill bg-secondary text-white py-2 px-5' style={{ marginBottom: -20, zIndex: 5 }}>Service Activation</div> */}
                        <div className="flex-grow-1 card w-100 text-center  rounded-5  overflow-hidden shadow">
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-4 py-2'>Service Activation</span>
                            </div>


                            <div className="px-4">
                                <div className="text-end mb-3">
                                    <div className="btn btn-secondary btn-sm  px-4 rounded-pill">Add</div>
                                </div>
                                <div className="d-flex justify-content-between fs-13">
                                    <div>Domain Renewal date :</div>
                                    <div style={{ color: COLOR.GREEN }}>23 of jan 2025</div>
                                    <div style={{ color: COLOR.BLUE }}>Update {Icons.updateCalendar("fs-6", {})}</div>
                                </div>

                                <div className="d-flex justify-content-between fs-13">
                                    <div>Hosting Renewal date :</div>
                                    <div style={{ color: COLOR.GREEN }}>23 of jan 2025</div>
                                    <div style={{ color: COLOR.BLUE }}>Update {Icons.updateCalendar("fs-6", {})}</div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

                <div className="row flex-grow-1">
                    <div className="col-7  d-flex flex-column align-items-center">
                        <div className="card w-100 h-100 text-center rounded-5 overflow-hidden shadow">
                            {/* <div className='mb-5'><span className='rounded-bottom-4 bg-secondary text-white py-3 px-5'>Portal Status Report</span></div> */}
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-4 py-2'>Portal Status Report</span>
                            </div>
                            <div className="p-4" style={{ maxHeight: 310 }}>

                                <div className="d-flex justify-content-between fs-13">
                                    <div>Domain Renewal date :</div>
                                    <div style={{ color: COLOR.GREEN }}>23 of jan 2025</div>
                                    <div className='text-danger'>(50 days from today)</div>
                                    <u style={{ color: COLOR.BLUE }}>UFix this problem now</u>
                                </div>

                                <div className="d-flex justify-content-between fs-13">
                                    <div>Hosting Renewal date :</div>
                                    <div style={{ color: COLOR.GREEN }}>23 of jan 2025</div>
                                    <div className='text-danger'>(50 days from today)</div>
                                    <u style={{ color: COLOR.BLUE }}>UFix this problem now</u>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="col-5 d-flex flex-column align-items-start">

                        {/* <div className='rounded-end-pill rounded-top-pill bg-secondary text-white py-2 px-5' style={{ marginBottom: -20, zIndex: 5 }}>Critical Information</div> */}

                        <div className="flex-grow-1 card w-100 text-start overflow-hidden shadow rounded-5 hide-scroll" >
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <span className='bg-primary rounded-bottom-4 text-white px-4 py-2'>Critical Information</span>
                            </div>
                            <div className='text-end px-4'>
                                {
                                    (getInfo?.data?.filter((i) => i.type === 'Elearning').length > 0) ?

                                        <ViewEditInfoModal refetch={getInfo.refetch} id={getInfo.data.filter((i) => i.type === 'Elearning')[0].id} subPortal={'Elearning'} isCreate={false} />
                                        :

                                        <ViewEditInfoModal refetch={getInfo.refetch} id={0} subPortal={'Elearning'} isCreate={true} />
                                    // <div className='rounded-start-pill rounded-bottom-pill bg-primary text-white py-2 px-5 align-self-end' style={{ marginTop: -20, zIndex: 5 }}>Create Information</div>

                                }
                            </div>
                            <div className="px-4 overflow-auto" style={{ maxHeight: 310 }}>
                                {
                                    getInfo?.data
                                        ?.filter((i) => i.type === 'Elearning')
                                        ?.map((i, index) => (
                                            <div key={index} dangerouslySetInnerHTML={{ __html: i.content }} />
                                        ))
                                }

                                {/* <div className='rounded-start-pill rounded-bottom-pill bg-primary text-white py-2 px-5 align-self-end' style={{ marginTop: -20, zIndex: 5 }}>Edit Information</div> */}



                            </div>

                        </div>
                    </div>

                </div>


            </div >
        </div >
    )


}

export default ELearningPortal