import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        
        updatePin: builder.mutation({ query: (body) => ({ url: `/changePin`, method: 'PATCH', body }) }),
        updatePassword: builder.mutation({ query: (body) => ({ url: `/changePassword`, method: 'PATCH', body }) }),

    }),
})


export const {useUpdatePasswordMutation, useUpdatePinMutation} = extendedApiSlice
