
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSupportTickets: builder.query({ query: () => `/admin/support`, keepUnusedDataFor: 0 }),
        getSupportTicketById: builder.query({ query: (id) => `/admin/support?id=${id}`, keepUnusedDataFor: 0 }),
        sendMessage: builder.mutation({ query: ({ id, body }) => ({ url: `/admin/support/chat?id=${id}`, method: 'POST', body }) }),
        closeSupport: builder.mutation({ query: ({ id, body }) => ({ url: `/admin/support/close?id=${id}`, method: 'PATCH', body }) }),
    }),
})


export const {
   useGetSupportTicketsQuery,
   useGetSupportTicketByIdQuery,
   useSendMessageMutation,useCloseSupportMutation
} = extendedApiSlice
