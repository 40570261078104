import React from 'react'
import SelectPortals from '../../components/pages/SelectPortals'

import spmkImg from "../../assets/images/spmkPortal/spmkHeader.png"
import satsangImg from "../../assets/images/spmkPortal/image 59.png"
import brahmImg from "../../assets/images/spmkPortal/image 61.png"

const SPMKPortals = () => {
  const portals = [
    { name: "SPMK Karyakar", img: satsangImg, color: "#002826", bgColor: "#DDFFFD", to: "./KaryakarPortal" },
    { name: "SPMK Gurukul ERP", img: satsangImg, color: "#2C2866", bgColor: "#DDDAFF", to: "./SaintsPortal" },
    { name: "Satsang Ayojan Vibhag", img: satsangImg, color: "#FEA8E8", bgColor: "#FFD8F5", to: "./AyojanVibhagPortal" },
    { name: "SPMK Brahmvidhya", img: brahmImg, color: "#FFCF2E", bgColor: "#FFE9A1", to: "./SpmkBrahmvidyaPortal" },
  ]

  return <SelectPortals headImg={spmkImg} portals={portals} />
}

export default SPMKPortals