import React from 'react'
import icgsGif from "../../assets/gif/portals.gif"
import brahmvidyaImg from "../../assets/images/Brahmvidya.png"
import educationImg from "../../assets/images/Education.png"
import eLearningImg from "../../assets/images/E-learning.png"
import managementImg from "../../assets/images/Management.png"
import SelectPortals from '../../components/pages/SelectPortals'

const ICGSPortals = () => {

  const portals = [
    { name: "Brahmvidhya Portal", img: brahmvidyaImg, color: "#002993", bgColor: "#D0DBF8", to: "./BrahmvidyaPortal" },
    { name: "Education Portal ", img: educationImg, color: "#7B0051", bgColor: "#F3DFEC", to: "./EducationPortal" },
    { name: "E-Learning Portal ", img: eLearningImg, color: "#840CA6", bgColor: "#E5BFF0", to: "./ELearningPortal" },
    { name: "Management Portal", img: managementImg, color: "#147629", bgColor: "#D1FFE4", to: "./ManagementPortal" },
  ]

  return <SelectPortals headImg={icgsGif} portals={portals} />
}

export default ICGSPortals 