import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Loading from '../ui/Loading'
import { useSignInMutation, useSignOutMutation } from '../../services/authSlice'
import ApiErrorModal from '../modal/ApiErrorModal'
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../../redux/authSlice'

const ProfileDropdown = () => {

    // const res = useGetUserDetailsQuery()
    const [signOut, signOutResponse] = useSignOutMutation();
    const [signIn, signInResponse] = useSignInMutation({ fixedCacheKey: "signIn" });
    const dispatch = useDispatch()

    const handleSignOut = (event) => {
        event.preventDefault()
        signOut();
        dispatch(setAuthToken(null))
    }


    return (
        <div className="dropdown">
            <button className="btn bg-white rounded-pill px-3 shadow-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Super Admin
            </button>
            <ul className="dropdown-menu ">
                <li><NavLink className="dropdown-item" to={"/SettingV1"}>Settings</NavLink></li>


                <li><button className="dropdown-item" onClick={handleSignOut}>Sign Out</button></li>
            </ul>
        </div>
    )
}

export default ProfileDropdown