import React from 'react'
import WarningRedImg from '../../assets/images/warning-red.png'
import { Link } from 'react-router-dom'
import Icons from '../ui/Icons'
import Button from '../form/Button'
import { usePatchReminderMutation } from '../../services/reminderSlice'
import ApiErrorModal from '../modal/ApiErrorModal'
import RemainderMemoModal from '../modal/RemainderMemoModal'

const DashReminder = ({ rem, refetch }) => {

    const [patchReminder, patchReminderRes] = usePatchReminderMutation();

    const onDone = () => {
        const body = { status: "Done" }
        patchReminder({ id: rem.id, body }).then((res) => {
            if (!res.error) {
                refetch()
            }
        })
    }

    const onSkippedPoint1 = () => {
        const body = { status: "Skipped Point 1" }
        patchReminder({ id: rem.id, body }).then((res) => {
            if (!res.error) {
                refetch()
            }
        })
    }

    const onSkippedPoint2 = () => {
        const body = { status: "Skipped Point 2" }
        patchReminder({ id: rem.id, body }).then((res) => {
            if (!res.error) {
                refetch()
            }
        })
    }

    return (
        <>
            {patchReminderRes.isError && <ApiErrorModal response={patchReminderRes} />}
            <div>
            {/* <div className='bg-danger'><RemainderMemoModal reminder={rem} refetch={refetch} /></div> */}
                {
                    rem.status === "Created"
                        ?
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                                <div className='me-3'>{Icons.warning('fs-1', { color: '#0FA958' })}</div>
                                <div className="flex-grow-1 text-break">{rem.title}: <br /><RemainderMemoModal reminder={rem} refetch={refetch} /></div>
                            </div>
                            
                        </div>
                        :
                        (
                            rem.status === "Skipped Point 1"
                                ?
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>{Icons.warning('fs-1', { color: '#FEC000' })}</div>
                                        <div className="flex-grow-1 text-break">{rem.title}: <br /><RemainderMemoModal reminder={rem} refetch={refetch} /></div>
                                    </div>
                                   
                                </div>
                                :
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>{Icons.warning('fs-1', { color: '#FF4040' })}</div>
                                        <div className="flex-grow-1 text-break">{rem.title}: <br /><RemainderMemoModal reminder={rem} refetch={refetch} /></div>
                                    </div>
                                  
                                </div>
                        )
                }
            </div>
            <hr />
        </>
    )
}

export default DashReminder