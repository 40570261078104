import React, { useState } from 'react'
import Button from '../form/Button';
import { getApiErrorMessage } from '../../utility/errorMessages';
import SuccessModal from './SuccessModal';
import Loading from '../ui/Loading';
import ApiErrorModal from './ApiErrorModal';
import Check from '../form/Check';
import { useGetInfosQuery, usePatchInfoMutation, usePostInfoMutation } from '../../services/infoSlice';
import FormattedInput from '../form/FormattedInput';

const ViewEditInfoModal = ({ refetch, id, subPortal, isCreate }) => {

    const modalId = "viewEditInfoModal" + id
    const formattedInputName = "formattedInput" + id

    const [patchInfo, patchInfoRes] = usePatchInfoMutation();
    const [createInfo, CreateInfoRes] = usePostInfoMutation();
    const res = useGetInfosQuery();

    const [formEnabled, setFormEnabled] = useState(false)

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {
            const body = {
                content: form[formattedInputName].value,
                type: subPortal
            };

            if (isCreate) {
                createInfo(body).then((res) => {
                    if (!res.error) {
                        refetch()
                        form.classList.remove('was-validated')
                        document.getElementById(modalId + "Button").click()
                    }
                })
            }
            else {
                patchInfo({ id, body }).then((res) => {
                    if (!res.error) {
                        refetch()
                        form.classList.remove('was-validated')
                        document.getElementById(modalId + "Button").click()
                    }
                })
            }

        }
        else form.classList.add('was-validated')

    }

    if (res.isLoading) return <Loading />
    if (res.isError) return <ApiErrorModal response={res} />

    const info = res.data.find(i => i.type === subPortal)



    return (
        <>

            {patchInfoRes.isSuccess && <SuccessModal message={"Info successfullly updated."} />}
            {
                subPortal
                    ?
                    <div className="btn btn-secondary btn-sm text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                        {isCreate ? 'Create' : `Update `}
                    </div>
                    :
                    <div className="btn btn-secondary btn-sm text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>View</div>
            }

            <div className="modal fade text-dark" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={onSubmit} noValidate>
                    <div className="modal-content bg-white rounded-4">
                        <div className="rounded-4">
                            <div className="modal-header py-0 border-0 position-relative">
                                <div className='px-5 bg-primary rounded-top-5 rounded-end-5 text-white py-2 position-absolute start-0'>Critical Information</div>
                            </div>
                            <div className="modal-body text-dark p-5">
                                <h5 className="fw-bold mb-3">Primary Details</h5>
                                <FormattedInput label={"Critical Info"} name={formattedInputName} defaultValue={info?.content} disabled={!formEnabled} />



                            </div>
                            <div className="modal-footer justify-content-end border-0">
                                {patchInfo.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {getApiErrorMessage(patchInfoRes).label}: {getApiErrorMessage(patchInfoRes).message}
                                    </div>
                                }

                                <Check name={"Check to edit"} setChecked={setFormEnabled} containerClass={"me-3"} required />



                                <Button
                                    className="btn btn-secondary rounded-pill px-4 shadow-sm"
                                    loadingLabel="Updating"
                                    res={patchInfoRes}
                                    disabled={!formEnabled}
                                >
                                    Update
                                </Button>

                            </div>
                        </div>
                    </div>
                </form >
            </div >

        </>
    )
}

export default ViewEditInfoModal