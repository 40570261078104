import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBranches: builder.query({ query: () => `/branch`, keepUnusedDataFor: 0 }),
        getBranch: builder.query({ query: (id) => `/branch?id=${id}`, keepUnusedDataFor: 0 }),
        postBranch: builder.mutation({ query: (body) => ({ url: `/branch`, method: 'POST', body }) }),
        patchBranch: builder.mutation({ query: ({ id, body }) => ({ url: `/branch?id=${id}`, method: 'PATCH', body }) }),
        deleteBranch: builder.mutation({ query: (id) => ({ url: `/branch?id=${id}`, method: 'DELETE' }) }),
    }),
})


export const { useGetBranchQuery, useGetBranchesQuery, usePostBranchMutation, usePatchBranchMutation, useDeleteBranchMutation } = extendedApiSlice
