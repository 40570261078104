import { NavLink } from 'react-router-dom'
import logoImg from "../../assets/images/logo.png"
import Icons from '../ui/Icons';


export const sideBarLinks = [
    { to: "/", text: "Dashboard", icon: Icons.dashboard },
    { to: "/MainBranchManagement", text: "Manage Main Branch", icon: Icons.mainBranch },
    { to: "/BranchManagement", text: "Branch Management", icon: Icons.branchManagement },
    { to: "/AdminManagement", text: "Admin Management", icon: Icons.adminManagement },
    { to: "/UserManagement", text: "User Management", icon: Icons.userManagement },
    { to: "/ReminderManagement", text: "Reminders", icon: Icons.updateCalendar },
    { to: "/ICGSPortals", text: "ICGS Portals", icon: Icons.icgsPortals },
    { to: "/ResearchPortals", text: "Research Portals", icon: Icons.researchPortals },
    { to: "/SPMKPortals", text: "SPMK Portals", icon: Icons.spmkPortals },
    { to: "/SettingV1", text: "Account Settings", icon: Icons.settings },
    { to: "/Tickets", text: "Resolve Tickets", icon: Icons.ticket },
];

const Sidebar = () => {

    const navItem = (link) => {
        return (
            <li key={link.text} className="nav-item">
                <NavLink to={link.to} className="nav-link d-flex align-items-center">
                    {link.icon("me-2", { width: 20, height: 20 })}
                    {link.text}
                </NavLink>
            </li>
        )
    }

    return (
        <div className="d-none d-lg-flex flex-column flex-shrink-0 bg-primary h-100 shadow" style={{ width: 280, zIndex: 1 }}>

            <div className='d-flex justify-content-center mb-3'>
                <div className="bg-white rounded-bottom-5 p-3" id="credManagerModalButton" data-bs-toggle="modal" data-bs-target="#credManagerModal">
                    <img src={logoImg} alt="" style={{ width: 150 }} />
                </div>

            </div>

            <div className='overflow-auto py-3'>
                <ul className="nav nav-pills flex-column mb-auto px-3">
                    {sideBarLinks.map(navItem)}
                </ul>
            </div>

        </div>
    )
}

export default Sidebar