import React from 'react'
import { usePatchReminderMutation } from '../../services/reminderSlice';
import Button from '../form/Button';
import { Link } from 'react-router-dom';

const RemainderMemoModal = ({ reminder, refetch }) => {

    const modalId = "reminderMemoModal" + reminder.id
    const modalBtnId = modalId + "Button"
    const [patchReminder, patchReminderRes] = usePatchReminderMutation();

    const onDone = () => {
        const body = { status: "Done" }
        patchReminder({ id: reminder.id, body }).then((res) => {
            if (!res.error) {
                document.getElementById(modalBtnId).click()
                refetch()
            }
        })
    }

    const onSkippedPoint1 = () => {
        const body = { status: "Skipped Point 1" }
        patchReminder({ id: reminder.id, body }).then((res) => {
            if (!res.error) {
                document.getElementById(modalBtnId).click()
                refetch()
            }
        })
    }

    const onSkippedPoint2 = () => {
        const body = { status: "Skipped Point 2" }
        patchReminder({ id: reminder.id, body }).then((res) => {
            if (!res.error) {
                document.getElementById(modalBtnId).click()
                refetch()
            }
        })
    }

    return (
        <>
            <Link style={{ color: '#4500D8' }} id={modalBtnId} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Check details</Link>

            <div className="modal fade" id={modalId} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-4 ">
                        <div className="modal-header py-0 border-0 d-flex justify-content-center">
                            <div className='px-5 bg-primary text-center text-white py-2 rounded-bottom-4 shadow '>Reminder</div>
                        </div>
                        <div className="modal-body">
                            <div className='fw-bold fs-3'>{reminder.title}</div>
                            <div className='pb-3'>Status: {reminder.status}</div>
                            <div dangerouslySetInnerHTML={{ __html: reminder.memo }} />
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <button type="button" className="px-5 mx-3 btn btn-success rounded-pill border border-3 border-white shadow shadow" data-bs-dismiss="modal">Back</button>
                            {/* <button type="button" className=" mx-3 btn btn-warning  rounded-pill border border-3 border-white shadow shadow">Skip for now</button> */}

                            {
                                reminder.status === "Created" ?
                                    <Button
                                        className="btn border border-3 border-white text-white shadow btn-warning rounded-pill"
                                        res={patchReminderRes}
                                        loadingLabel={"Updating"}
                                        type="button"
                                        onClick={onSkippedPoint1}
                                    >
                                        Skipped Point 1
                                    </Button>
                                    :
                                    reminder.status === "Skipped Point 1" ?
                                        <Button
                                            className="btn border border-3 border-white text-white shadow btn-warning rounded-pill"
                                            res={patchReminderRes}
                                            loadingLabel={"Updating"}
                                            type="button"
                                            onClick={onSkippedPoint2}
                                        >
                                            Skipped Point 2
                                        </Button>
                                        :
                                        <Button
                                            className="btn border border-3 border-white text-white shadow px-5 btn-success rounded-pill "
                                            data-bs-dismiss="modal"
                                            res={patchReminderRes}
                                            loadingLabel={"Updating"}
                                            type="button"
                                            onClick={onDone}
                                        >
                                            Done
                                        </Button>
                            }

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RemainderMemoModal