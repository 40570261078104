import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMainBranches: builder.query({ query: () => `/mainbranch`, keepUnusedDataFor: 0 }),
        getMainBranch: builder.query({ query: (id) => `/mainbranch?id=${id}`, keepUnusedDataFor: 0 }),
        postMainBranch: builder.mutation({ query: (body) => ({ url: `/mainbranch`, method: 'POST', body }) }),
        patchMainBranch: builder.mutation({ query: ({ id, body }) => ({ url: `/mainbranch?id=${id}`, method: 'PATCH', body }) }),
        deleteMainBranch: builder.mutation({ query: (id) => ({ url: `/mainbranch?id=${id}`, method: 'DELETE' }) }),
    }),
})


export const { useGetMainBranchQuery, useGetMainBranchesQuery, usePostMainBranchMutation, usePatchMainBranchMutation, useDeleteMainBranchMutation } = extendedApiSlice
