import React from 'react'
import { useGetTermsQuery, useGetUserQuery } from '../../services/userSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modal/ApiErrorModal'
import Api from '../../constants/Api'
import Icons from '../../components/ui/Icons'

const SettingsV1 = () => {

    // const response = useGetUserQuery()
    // const getTerms = useGetTermsQuery()

    // if (response.isLoading) return <Loading />
    // if (response.isLoading || getTerms.isLoading) return <Loading />
    // if (response.isError) return <ApiErrorModal response={response} />
    // if (getTerms.isError) return <ApiErrorModal response={getTerms} />

    return (
        <div className="h-100 p-4" style={{ zIndex: 5 }}>
            <section className="section profile bg-white rounded-5 h-100 overflow-auto">
                <div className="row h-100">
                    <div className="col-xl-4 border-2 border-end h-100 ">
                        <div className="card position-sticky sticky-top border-0" >
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center" >
                                <div className="profcardtop"></div>
                                <img alt="Profile" className="rounded-circle"
                                    src="http://62.72.31.126:3001/static/media/userPhoto.faaa26e49109ec238a37.png" />
                                {/* <h2>{response.data.name}</h2> */}
                                {/* <h3>{response.data.post}</h3> */}
                                <h2>Swami Narayan</h2>
                                <h3>xyz</h3>
                            </div>
                            <div className="card border-0">
                                <hr className="mx-4" />
                                <div className="p-4">
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="fw-bold">
                                            {Icons.gear('fs-1')}
                                        </div>
                                        <div className="ms-3">
                                            for technical support
                                        </div>
                                    </div>
                                    <div className="fw-bold">
                                        Call Us
                                    </div>
                                    <div className="text-muted mb-4">
                                        +91- 9284203442
                                    </div>
                                    <div className="fw-bold">
                                        Email Us
                                    </div>
                                    <div className="text-muted mb-4">
                                        Vidhya@sgrs.org
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 ">
                        <div className="card border-0">
                            <div className="card-body p-0">
                                <ul className="nav nav-tabs nav-tabs-bordered position-sticky sticky-top bg-white settings-tabs"
                                    role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link active" data-bs-target="#primary-details"
                                            data-bs-toggle="tab" role="tab" tabIndex="-1">Primary Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="true" className="nav-link" data-bs-target="#contact-details"
                                            data-bs-toggle="tab" role="tab">Contact</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link" data-bs-target="#credentials"
                                            data-bs-toggle="tab" role="tab" tabIndex="-1">Credentials</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link" data-bs-target="#twofa"
                                            data-bs-toggle="tab" role="tab" tabIndex="-1">2FA</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link" data-bs-target="#terms"
                                            data-bs-toggle="tab" role="tab" tabIndex="-1">Terms</button>
                                    </li>
                                </ul>
                                <div className="tab-content p-3">
                                    <div className="tab-pane fade profile-overview active show" id="primary-details"
                                        role="tabpanel">
                                        <h5 className="card-title">
                                            About
                                        </h5>
                                        <p className=" fst-italic">
                                            Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora
                                            libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem
                                            eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.
                                        </p>
                                        <h5 className="card-title">
                                            Profile Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label fw-bold text-black">
                                                Name
                                            </div>
                                            {/* <div className="col-lg-9 col-md-8">{response.data.name}</div> */}
                                            <div className="col-lg-9 col-md-8">Swami Narayan</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label fw-bold text-black">
                                                Branch
                                            </div>
                                            {/* <div className="col-lg-9 col-md-8">{response.data.branch.branchName}</div> */}
                                            <div className="col-lg-9 col-md-8">Admin Branch</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label fw-bold text-black">
                                                Post
                                            </div>
                                            {/* <div className="col-lg-9 col-md-8">{response.data.post}</div> */}
                                            <div className="col-lg-9 col-md-8">Demo Post</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label fw-bold text-black">
                                                SUID
                                            </div>
                                            {/* <div className="col-lg-9 col-md-8">{response.data.sUid}</div> */}
                                            <div className="col-lg-9 col-md-8">demo SUID</div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="contact-details" role="tabpanel">
                                        <h5 className="card-title">
                                            Contact Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label fw-bold text-black">
                                                Email
                                            </div>
                                            {/* <div className="col-lg-9 col-md-8">{response.data.email}</div> */}
                                            <div className="col-lg-9 col-md-8">demo@superadmin.com</div>
                                        </div>
                                        <p className="fs-13 mb-3">(This email will serve as the primary means for communicating
                                            important details and updates to enhance your overall experience.)</p>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label fw-bold text-black">
                                                Contact Number
                                            </div>
                                            {/* <div className="col-lg-9 col-md-8">{response.data.phoneNumber}</div> */}
                                            <div className="col-lg-9 col-md-8">1234567890</div>
                                        </div>
                                        <p className="fs-13 mb-3">(All crucial communications will be conveyed through this
                                            designated contact number.)</p>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="credentials" role="tabpanel">

                                        <form>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label fw-bold text-black" htmlFor="email">Email</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="email" name="email" type="email" />
                                                </div>
                                            </div>
                                            <p className="fs-13 mb-3">If you suspect that your user ID is at risk of unauthorized
                                                access, please promptly contact the SGRS Utility Service Administrator to update
                                                your user ID. Failure to uphold account security measures may result in
                                                temporary or permanent suspension from all Utility services. Please refer to the
                                                terms and conditions for further information.</p>
                                            <p className="fs-13 mb-3">To update your ID and password, enter your current password,
                                                input your new password, undergo authentication via 2FA, and then proceed to
                                                change your password.</p>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label fw-bold text-black" htmlFor="currentPassword">Current
                                                    Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="currentPassword" name="password"
                                                        type="password" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label fw-bold text-black" htmlFor="newPassword">New
                                                    Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="newPassword" name="newpassword"
                                                        type="password" />
                                                </div>
                                            </div>
                                            <div className="text-end">
                                                <button className="btn btn-primary" type="submit">Change Password</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="twofa" role="tabpanel">

                                        <form>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label fw-bold text-black" htmlFor="mail">Email</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="mail" name="mail" type="email" />
                                                </div>
                                            </div>
                                            <p className="fs-13 mb-3">(This email will serve as your secondary factor for
                                                authentication.)</p>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label fw-bold text-black" htmlFor="contactnumber">Contact
                                                    Number</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="numbermob" name="mobnumber" type="tel" />
                                                </div>
                                            </div>
                                            <p className="fs-13 mb-3">(This contact number will be employed as your secondary factor
                                                for authentication. Please note that 2FA via contact number is currently
                                                unavailable.)</p>
                                            <div className="text-end">
                                                <button className="btn btn-primary" type="submit">Save Settings</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="terms" role="tabpanel">
                                        <form>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label fw-bold text-black" htmlFor="terms">Terms</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <textarea
                                                        name="terms"
                                                        className="form-control"
                                                        id="terms"
                                                        style={{ height: 100 }}
                                                        spellCheck="false"
                                                        data-ms-editor="true"
                                                    // defaultValue={getTerms.data[0]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-end">
                                                <button className="btn btn-primary" type="submit">Save Settings</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

    // return (
    //     <div className="container h-100">

    //         <div className="row bg-success h-100">

    //             <div className="col bg-danger">
    //                 <div className="bg-white  text-center h-100 d-flex flex-column align-items-center justify-content-center">
    //                     <img src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${response.data.profilePicture.id}${response.data.profilePicture.ext}`} alt="" className='borde rounded-circle shadow-sm' style={{ width: 100, height: 100 }} />
    //                     <p className='fw-bold'>{response.data.name}</p>
    //                     <p className='fs-11'>{response.data.post}</p>
    //                     <hr className='border' />
    //                 </div>
    //             </div>

    //             <div className="col">Tabs</div>

    //         </div>

    //     </div>
    // )
}

export default SettingsV1