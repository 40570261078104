import React, { useState } from 'react'
import Input from '../../components/form/Input'
import userImg from '../../assets/images/userPhoto.png'
import { useUpdatePasswordMutation } from '../../services/updateSlice'
import SuccessModal from '../../components/modal/SuccessModal'
import ApiErrorModal from '../../components/modal/ApiErrorModal'
import Icons from '../../components/ui/Icons'

const Settings = () => {

    const containerClass = "col mb-4"
    const [selectedTab, setSelectedTab] = useState("Primary Details")
    const activeTabClassName = "border-start border-5 border-primary p-4 fw-bold";
    const inactiveTabClassName = "p-4 fw-bold";

    const [changePassword, changePasswordRes] = useUpdatePasswordMutation()

    const changePassOnSubmit = (e) => {
        e.preventDefault()

        const form = e.target;

        if (form.checkValidity()) {

            const body = {
                email: form['Email'].value,
                currentPassword: form['Old Password'].value,
                newPassword: form['New Password'].value,
            }

            changePassword(body)
                .then((res) => {
                    if (!res.error) {
                        form.reset()
                        form.classList.remove('was-validated')
                    }
                })

        }
        else form.classList.add('was-validated')

    }

    return (
        <div className='py-5 d-flex h-100 justify-content-between align-items-center'>

            {changePasswordRes.isSuccess && <SuccessModal label={"Password Changed!"} message={"Password has been changed."} closeTo={"/settings"} />}
            {changePasswordRes.isError && <ApiErrorModal response={changePasswordRes} />}

            <div className='my-5 mx-2 d-flex h-100 align-items-center'>

                <div className='bg-white rounded-start-4 rounded-end-5 shadow overflow-hidden ' style={{ zIndex: 50, width: 300 }}>
                    <h3 className='fw-bold text-danger mb-4 p-4' style={{ backgroundColor: "#F5F5F5" }}>Settings</h3>
                    <div className={selectedTab === "Primary Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Primary Details")}>Primary Details</div>
                    <div className={selectedTab === "Contact Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Contact Details")}>Contact Details</div>
                    <div className={selectedTab === "Login ID & Password" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Login ID & Password")}>Login ID & Password</div>
                    <div className={selectedTab === "2FA" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("2FA")}>2 Factor Authentication (2FA)</div>
                    <hr className='mx-4' />
                    <div className='p-4'>
                        <div className='d-flex align-items-center mb-4'>
                            <div className='fw-bold'>{Icons.gear('fs-2')}</div>
                            <div className='ms-3'>for technical support</div>
                        </div>
                        <div className='fw-bold'>Call Us</div>
                        <div className='text-muted mb-4'>+91- 9284203442</div>
                        <div className='fw-bold'>Email Us</div>
                        <div className='text-muted mb-4'>Vidhya@sgrs.org</div>
                    </div>
                </div>

                {selectedTab === "Primary Details" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={userImg} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>Janmangal Swami</p>
                                <p className='fs-11'>Post</p>
                            </div>
                        </div>

                        <Input name={"Name"} containerClass={containerClass} required />
                        <Input name={"Branch"} containerClass={containerClass} required />
                        <Input name={"Post"} containerClass={containerClass} required />
                        <Input name={"S-UID"} containerClass={containerClass} required />

                        <p className='fs-11'>To update your primary details, kindly get in touch with the SGRS Utility Service Provider
                            or reach out to our dedicated technical support team for assistance.</p>
                    </div>
                }

                {selectedTab === "Contact Details" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={userImg} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>Janmangal Swami</p>
                                <p className='fs-11'>Post</p>
                            </div>
                        </div>
                        <Input name={"Email"} containerClass={containerClass} required />
                        <p className='fs-11 mb-5'>(All crucial communications will be conveyed through this designated contact number.)</p>
                        <Input name={"Contact Number"} containerClass={containerClass} required />
                        <p className='fs-11 mb-5'>(This email will serve as the primary means for communicating important details
                            and updates to enhance your overall experience.)</p>

                        <div className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</div>
                    </div>
                }

                {selectedTab === "Login ID & Password" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={userImg} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>Janmangal Swami</p>
                                <p className='fs-11'>Post</p>
                            </div>
                        </div>
                        <form className="" onSubmit={changePassOnSubmit} noValidate>
                            <Input name={"Email"} containerClass={containerClass} required />
                            <p className='fs-11 mb-5'>If you suspect that your user ID is at risk of unauthorized access,
                                please promptly contact the SGRS Utility Service Administrator to update your user ID.
                                Failure to uphold account security measures may result in temporary or permanent suspension from all Utility services.
                                Please refer to the terms and conditions for further information.</p>
                            <p className='fs-11 mb-3'>To update your ID and password, enter your current password,
                                input your new password, undergo authentication via 2FA,
                                and then proceed to change your password.</p>
                            <Input name={"Old Password"} containerClass={containerClass} required />
                            <Input name={"New Password"} containerClass={containerClass} required />
                            {/* <Input name={"2FA Code (Email)"} containerClass={containerClass} required /> */}
                            <button className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</button>
                        </form>
                    </div>
                }

                {selectedTab === "2FA" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={userImg} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>Janmangal Swami</p>
                                <p className='fs-11'>Post</p>
                            </div>
                        </div>
                        <Input name={"Email"} containerClass={containerClass} required />
                        <p className='fs-11 mb-5'>(This email will serve as your secondary factor for authentication.)</p>
                        <Input name={"Contact Number"} containerClass={containerClass} required />
                        <p className='fs-11 mb-3'>(This contact number will be employed as your secondary factor for authentication.
                            Please note that 2FA via contact number is currently unavailable.)</p>
                        <div className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</div>
                    </div>
                }

            </div>

            <div className='py-5 h-100'>
                <div className='d-flex flex-column h-100 overflow-hidden rounded-start-5 shadow' style={{ width: 300 }}>
                    <div className='bg-info  p-3 d-flex justify-content-between px-3 align-items-center '>
                        <div className='fw-bold text-danger'>Terms and Conditions</div>
                        <div className='rounded-4 bg-primary btn btn-sm text-white px-3 py-1'>Edit</div>
                    </div>
                    <div className='flex-grow-1 bg-white'></div>
                    <div className='text-center bg-info  fw-bold p-3'>Accepted <span className='text-dark fs-11'>On 23rd Jan; 2023 (12:30 IST)</span></div>
                </div>
            </div>

        </div>
    )
}

export default Settings
