
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAdmins: builder.query({ query: () => `/admin`, keepUnusedDataFor: 0 }),
        getAdmin: builder.query({ query: (id) => `/admin?id=${id}`, keepUnusedDataFor: 0 }),
        postAdmin: builder.mutation({ query: (body) => ({ url: `/admin`, method: 'POST', body }) }),
        patchAdmin: builder.mutation({ query: ({ id, body }) => ({ url: `/admin?id=${id}`, method: 'PATCH', body }) }),
        deleteAdmin: builder.mutation({ query: (id) => ({ url: `/admin?id=${id}`, method: 'DELETE' }) }),
        
        patchToggleBramvidya: builder.mutation({ query: (body) => ({ url: `/toggle-brahmvidya-service`, method: 'PATCH', body }) }),
        patchToggleEducation: builder.mutation({ query: (body) => ({ url: `/toggle-education-service`, method: 'PATCH', body }) }),

        
        
       
        patchToggleSmpkBramvidya: builder.mutation({ query: (body) => ({ url: `/toggle-spmkBrahmvidya-service`, method: 'PATCH', body }) }),
        patchToggleSgrsKaryakar: builder.mutation({ query: (body) => ({ url: `/toggle-sgrsKaryakar-service`, method: 'PATCH', body }) }),
        patchToggleMainBranchOffice: builder.mutation({ query: (body) => ({ url: `/toggle-mainBranchOffice-service`, method: 'PATCH', body }) }),
        patchToggleBranchErpOffice: builder.mutation({ query: (body) => ({ url: `/toggle-brancherp-service`, method: 'PATCH', body }) }),

   
    }),
})


export const { useGetAdminQuery, useGetAdminsQuery, usePostAdminMutation, usePatchAdminMutation, useDeleteAdminMutation , usePatchToggleBramvidyaMutation,usePatchToggleEducationMutation , usePatchToggleBranchErpOfficeMutation,usePatchToggleMainBranchOfficeMutation,usePatchToggleSgrsKaryakarMutation,usePatchToggleSmpkBramvidyaMutation} = extendedApiSlice
