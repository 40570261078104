
const SUPER_ADMIN_BACKEND_URL = process.env.BACKEND || 'https://sa.sgrsutility.in/'
// const SUPER_ADMIN_BACKEND_URL =  'http://localhost:3000/'

const BRAHMVIDHYA_BACKEND_URL = 'https://br.icgs.sgrsutility.in/'
const BRAHMVIDHYA_USER_URL = 'https://br.icgs.sgrsutility.org/'
const BRAHMVIDHYA_ADMIN_URL = 'https://admin.br.icgs.sgrsutility.org/'

const EDUCATION_BACKEND_URL = 'https://edu.icgs.sgrsutility.in/'
const EDUCATION_USER_URL = 'https://edu.icgs.sgrsutility.org/'
const EDUCATION_ADMIN_URL = 'https://admin.edu.icgs.sgrsutility.org/'

const SPMK_BACKEND_URL = 'https://spmk.sgrsutility.in/'
const SPMK_BRAHMVIDHYA_URL = 'https://spmk.sgrsutility.org/'
const SPMK_KARYAKAR_URL = 'https://karyakar.sgrsutility.org/'
const SPMK_BRANCH_ERP_URL = 'https://erp.sgrsutility.org/'

export default {
    BASE_URL: SUPER_ADMIN_BACKEND_URL,
    ADMIN_URL: SUPER_ADMIN_BACKEND_URL + "superAdmin/",
    PROFILE_PICTURE_URL: SUPER_ADMIN_BACKEND_URL + "uploads/images/profilePictures/",

    BRAHMVIDHYA_BACKEND_URL, BRAHMVIDHYA_USER_URL, BRAHMVIDHYA_ADMIN_URL,
    EDUCATION_BACKEND_URL, EDUCATION_USER_URL, EDUCATION_ADMIN_URL,
    SPMK_BACKEND_URL, SPMK_BRAHMVIDHYA_URL, SPMK_KARYAKAR_URL, SPMK_BRANCH_ERP_URL,

}