import React, { useEffect, useState } from "react";
import {
  useGetBranchQuery,
  usePatchBranchMutation,
  usePostBranchMutation,
} from "../../services/branchSlice";
import ApiErrorModal from "../../components/modal/ApiErrorModal";
import Icons from "../../components/ui/Icons";
import Bar from "../../components/form/Bar";
import Input from "../../components/form/Input";
import Check from "../../components/form/Check";
import Select from "../../components/form/Select";
import SuccessModal from "../../components/modal/SuccessModal";
import ResponsiveContainerCard from "../../components/ui/ResponsiveContainerCard";
import { Link, useParams } from "react-router-dom";
import Button from "../../components/form/Button";
import Loading from "../../components/ui/Loading";
import BranchDeleteModal from "../../components/modal/BranchDeleteModal";
import ImageInput from "../../components/form/ImageInput";
import Api from "../../constants/Api";
import { useGetMainBranchesQuery } from "../../services/mainBranchSlice";
import SelectWithLabels from "../../components/form/SelectWithLabels";

const EditBranch = () => {
  const { id } = useParams();
  const response = useGetMainBranchesQuery();
  const branchRes = useGetBranchQuery(id);
  const [patchBranch, patchBranchRes] = usePatchBranchMutation();
  const [hostelFacility, setHostelFacility] = useState("Yes");
  const [branchType, setBranchType] = useState("Day School");
  const [formEnabled, setFormEnabled] = useState(false);

  const labelClass = "me-3 text-nowrap";
  const consentLabel =
    "By checking this box I hereby give my consent for the branch registry and that the branch will abide by polices, terms and conditions for continuing any utility service provided by the SGRS Utility Administrator. And that in any case of awry, SGRS UTILITY Administrator’s decision will be final for all settlements.";

  const schooDetailsCondition = (type) =>
    type === "Day School" || type === "Gurukul" || type === "Kanya Gurukul";

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      let body = new FormData();

      if (form["Branch Photo"].files.length > 0)
        body.append("profilePicture", form["Branch Photo"].files[0]);

      body.append("branchName", form["Branch Name"].value);
      body.append("branchId", form["Branch ID"].value);
      body.append("dateEstablished", form["Date Established"].value);
      body.append("address", form["Address"].value);
      body.append("state", form["State"].value);
      body.append("country", form["Country"].value);

      body.append("mainBranchId", form["Main Branch"].value);
      body.append("externalLink", form["External Link"].value);
      body.append("status", form["Status"].value);

      const branchType = form["Branch Type"].value;
      body.append("branchType", branchType);

      if (schooDetailsCondition(branchType)) {
        body.append("schoolType", form["School Type"].value);
        body.append("gradeLevel", form["School Grade Levels"].value);

        body.append("currentBoard", form["Current Board"].value);
        body.append("medium", form["Medium"].value);
        body.append("affilationDate", form["Affiliation Date"].value);

        body.append("accreditation", form["Accreditations"].value);
        body.append("studentCapacity", form["School Student Capacity"].value);
        body.append("hostelFacility", form["Hostel Facility"].value === "Yes");

        body.append(
          "hostelType",
          form["Hostel Type"] ? form["Hostel Type"].value : null
        );
        body.append(
          "hostelGradeLevel",
          form["Hostel Grade Levels"] ? form["Hostel Grade Levels"].value : null
        );
        body.append(
          "hostelStudentCapacity",
          form["Hostel Student Capacity"]
            ? form["Hostel Student Capacity"].value
            : null
        );
      }

      body.append("consenterName", form["Consenter Name"].value);
      body.append("pin", form["Pin"].value);

      patchBranch({ id, body }).then((res) => {
        if (!res.error) {
          branchRes.refetch();
          form.classList.remove("was-validated");
        }
      });
    } else form.classList.add("was-validated");
  };

  useEffect(() => {
    if (branchRes.isSuccess && branchRes.data) {
      setHostelFacility(branchRes.data.hostelFacility ? "Yes" : "No");
      setBranchType(branchRes.data.branchType);
    }
  }, [branchRes.data, branchRes.isSuccess]);

  if (branchRes.isLoading || response.isLoading) return <Loading />;
  if (branchRes.isError) return <ApiErrorModal response={branchRes} />;
  if (response.isError) return <ApiErrorModal response={response} />;

  const branch = branchRes.data;
  const branches = response.data.map((branch) => ({
    label: branch.id + " " + branch.name,
    value: branch.id,
  }));

  return (
    <ResponsiveContainerCard
      titleBar={() => (
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className="d-flex">
            {Icons.branchManagement("text-primary fs-1 mb-1 me-4", {})}
            <div className="fs-2 fw-bold mb-1">
              <span className="text-danger">
                {formEnabled ? "Edit" : "View"}
              </span>{" "}
              Branch
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Check
              name={"Check to edit"}
              setChecked={setFormEnabled}
              containerClass={"me-3"}
              required
            />
            <BranchDeleteModal id={id} />
            <Link to={-1} className="btn btn-primary">
              Back
            </Link>
          </div>
        </div>
      )}
    >
      {patchBranchRes.isError && <ApiErrorModal response={patchBranchRes} />}
      {patchBranchRes.isSuccess && (
        <SuccessModal
          label="Branch Created"
          message="Branch successfully created."
          to={{ link: "/BranchManagement", text: "Go to Branch Management" }}
        />
      )}

      <form className="" onSubmit={onSubmit} noValidate>
        <Bar label={"Primary Details"}>
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-grow-1 mb-2 text-center text-lg-start">
              <ImageInput
                name={"Branch Photo"}
                defaultValue={
                  Api.PROFILE_PICTURE_URL +
                  branch?.profilePicture?.id +
                  branch?.profilePicture?.ext
                }
                disabled={!formEnabled}
              />
            </div>

            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <Input
                  name={"Branch Name"}
                  defaultValue={branch.branchName}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Branch ID"}
                  placeholder={"Ex: G01"}
                  defaultValue={branch.branchId}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Date Established"}
                  type={"date"}
                  placeholder={"DD/MM/YYYY"}
                  defaultValue={
                    branch.dateEstablished
                      ? new Date(branch.dateEstablished)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  disabled={!formEnabled}
                  required
                />

                <Input
                  name={"Address"}
                  placeholder={""}
                  defaultValue={branch.address}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"State"}
                  placeholder={""}
                  defaultValue={branch.state}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Country"}
                  placeholder={""}
                  defaultValue={branch.country}
                  disabled={!formEnabled}
                  required
                />

                <SelectWithLabels
                  name={"Main Branch"}
                  options={branches}
                  defaultValue={branch.mainBranch.id}
                  disabled={!formEnabled}
                  required
                />
                {/* <Input name={"Main Branch"} placeholder={"Rajkot gurukul"} defaultValue={branch.mainBranch} disabled={!formEnabled} required /> */}
                <Input
                  name={"External Link"}
                  defaultValue={branch.externalLink}
                  disabled={!formEnabled}
                  placeholder={"abc/anb.com"}
                />
                <Select
                  name={"Status"}
                  options={["Active", "Suspended", "Blocked"]}
                  labelClass={labelClass}
                  defaultValue={branch.status}
                  disabled={!formEnabled}
                />
                <Select
                  name={"Branch Type"}
                  options={[
                    "Gurukul",
                    "Kanya Gurukul",
                    "Day School",
                    "Mandir",
                    "Mahila Mandir",
                  ]}
                  labelClass={labelClass}
                  setValue={setBranchType}
                  defaultValue={branch.branchType}
                  disabled={!formEnabled}
                />
              </div>
            </div>
          </div>
        </Bar>

        {schooDetailsCondition(branchType) && (
          <Bar label={"School details"}>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
              <Input
                name={"School Type"}
                value={branchType === "Kanya Gurukul" ? "Girls" : "Boys"}
                defaultValue={branch.schoolType}
                disabled
              />
              <Input
                name={"School Grade Levels"}
                labelName={"Grade Levels"}
                placeholder={"1-12"}
                defaultValue={branch.gradeLevel}
                disabled={!formEnabled}
              />

              <Input
                name={"Current Board"}
                placeholder={"Ex: CBSE"}
                defaultValue={branch.currentBoard}
                disabled={!formEnabled}
              />
              <Input
                name={"Medium"}
                placeholder={"Language"}
                defaultValue={branch.medium}
                disabled={!formEnabled}
              />
              <Input
                name={"Affiliation Date"}
                type={"date"}
                placeholder={"DD/MM/YYYY"}
                defaultValue={
                  branch.affilationDate
                    ? new Date(branch.affilationDate)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                disabled={!formEnabled}
              />

              <Input
                name={"Accreditations"}
                placeholder={"Facilitation if any"}
                defaultValue={branch.accreditation}
                disabled={!formEnabled}
              />
              <Input
                name={"School Student Capacity"}
                labelName={"Student Capacity"}
                placeholder={"500"}
                defaultValue={branch.studentCapacity}
                disabled={!formEnabled}
              />
              <Select
                name={"Hostel Facility"}
                options={["Yes", "No"]}
                setValue={setHostelFacility}
                labelClass={labelClass}
                value={hostelFacility}
                disabled={!formEnabled}
              />
            </div>
          </Bar>
        )}

        {schooDetailsCondition(branchType) && hostelFacility === "Yes" && (
          <Bar label={"Hostel facility"}>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
              <Input
                name={"Hostel Type"}
                value={branchType === "Kanya Gurukul" ? "Girls" : "Boys"}
                disabled
              />
              <Input
                name={"Hostel Grade Levels"}
                labelName={"Grade Levels"}
                placeholder={"6-10"}
                defaultValue={branch.hostelGradeLevel}
                disabled={!formEnabled}
              />
              <Input
                name={"Hostel Student Capacity"}
                labelName={"Student Capacity"}
                placeholder={"500"}
                defaultValue={branch.hostelStudentCapacity}
                disabled={!formEnabled}
              />
            </div>
          </Bar>
        )}

        <Bar
          label={"CONSENT"}
          containerClass={"flex-column py-3 align-items-start"}
        >
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3  mb-3">
            <Input
              name={"Consenter Name"}
              defaultValue={branch.consenterName}
              disabled={!formEnabled}
              required
            />
            <Input name={"Pin"} disabled={!formEnabled} required />
          </div>

          <div className="row row-cols-1 align-items-center justify-content-end px-3 mb-3 g-3">
            <Check
              name={"consent"}
              label={consentLabel}
              containerClass={"col col-lg-10"}
              disabled={!formEnabled}
              required
            />

            <Button
              className="col col-lg-2 btn btn-secondary rounded-pill text-nowrap"
              disabled={!formEnabled}
              res={{
                isLoading: patchBranchRes.isLoading || branchRes.isFetching,
              }}
              loadingLabel={"Updating"}
            >
              Update Branch
            </Button>
          </div>
        </Bar>
      </form>
    </ResponsiveContainerCard>
  );
};

export default EditBranch;
