import React, { useState } from 'react'
import Input from '../form/Input'
import { usePostReminderMutation } from '../../services/reminderSlice';
import Select from '../form/Select';
import Button from '../form/Button';
import { getApiErrorMessage } from '../../utility/errorMessages';
import SuccessModal from './SuccessModal';
import FormattedInput from '../form/FormattedInput';

const PostReminderModal = ({ refetch, subPortal }) => {

    const modalId = "postReminderModal"
    const [postReminder, postReminderRes] = usePostReminderMutation();

    const statusOptions = ["Created", "Skipped Point 1", "Skipped Point 2", "Done"]
    const reccuringOptions = ["ONCE", "MONTLY", "YEARLY"]

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {


            const body = {
                title: form['Reminder Title'].value,
                memo: form['Reminder Memo'].value,
                pointOne: form['Reminder Point 1'].value,
                pointTwo: form['Reminder Point 2'].value,
                endPoint: form['Task End Point'].value,
                portal: form['Portal'].value,
                status: form['Status'].value,
                reccuring: form['Reccuring'].value,
            };

            postReminder(body).then((res) => {
                if (!res.error) {
                    refetch()
                    form.reset()
                    form.classList.remove('was-validated')
                    document.getElementById(modalId + "Button").click()
                }
            })

        }
        else form.classList.add('was-validated')

    }


    return (
        <>

            {postReminderRes.isSuccess && <SuccessModal message={"Reminder successfullly created."} />}

            {
                subPortal
                    ?
                    <div className="btn btn-secondary btn-sm  rounded-pill  px-4" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Add</div>
                    :
                    <div className="btn btn-secondary btn-sm  rounded-pill  px-4" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>Add Reminder</div>
            }

            <div className="modal fade text-dark" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={onSubmit} noValidate>
                    <div className="modal-content bg-white rounded-4">
                        <div className="rounded-4 ">
                            <div className="modal-header py-0 border-0 position-relative">
                                <div className='px-5 bg-primary rounded-top-5 rounded-end-5 text-white py-2 position-absolute start-0'>Important Reminder Memos</div>
                            </div>
                            <div className="modal-body text-dark p-5">
                                <h5 className="fw-bold mb-3">Primary Details</h5>
                                <Input name={"Reminder Title"} containerClass={'col mb-4'} required />
                                <FormattedInput name={"Reminder Memo"} />
                                <h5 className="fw-bold mt-5 mb-3">Reminder Date</h5>
                                <div className='row row-cols-2 g-3'>
                                    <Input name={"Reminder Point 1"} type={"date"} required />
                                    <Input name={"Reminder Point 2"} type={"date"} required />
                                    <Input name={"Task End Point"} type={"date"} required />
                                    <Select name={"Portal"} options={["Super Admin", "ICGS Brahmvidya", "ICGS Education"]} required />
                                    <Select name={"Status"} options={statusOptions} value={statusOptions[0]} disabled required />
                                    <Select name={"Reccuring"} options={reccuringOptions} required />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-end border-0">
                                {postReminderRes.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {getApiErrorMessage(postReminderRes).label}: {getApiErrorMessage(postReminderRes).message}
                                    </div>
                                }

                                <Button
                                    className="btn btn-secondary rounded-pill px-4 shadow-sm"
                                    loadingLabel="Creating"
                                    res={postReminderRes}
                                >
                                    Create
                                </Button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default PostReminderModal