import React, { useState } from "react";
import { useGetUsersQuery } from "../../services/userSlice";

import { Link, useParams } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modal/ApiErrorModal";
import TableContainerCard from "../../components/ui/TableContainerCard";
import ResponsiveContainerCard from "../../components/ui/ResponsiveContainerCard";
import Icons from "../../components/ui/Icons";
import { useGetBranchesQuery } from "../../services/branchSlice";
import Select from "../../components/form/Select";
import Image from "../../components/ui/Image";
import Api from "../../constants/Api";

const UserManagement = () => {
  const { portal } = useParams();
  const response = useGetUsersQuery();
  const branches = useGetBranchesQuery();
  const backgroundColor = "#04143E";
  const headers = [
    "SNo",
    "Name",
    "SUID",
    "Branch",
    "Email",
    "Status",
    "Actions",
  ];
  const [selectedBranch, setSelectedBranch] = useState("No Branch");

  const getTableHead = () => (
    <thead>
      <tr>
        {headers.map((header) => (
          <th key={header} className="text-white sticky-top bg-primary">
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const getStatusTag = (status) => {
    const commonClass = "rounded-pill text-white fw-bold px-3 ";
    switch (status) {
      case "Active":
        return <span className={commonClass + "bg-success"}>{status}</span>;
      case "Suspended":
        return <span className={commonClass + "bg-warning"}>{status}</span>;
      case "Blocked":
        return <span className={commonClass + "bg-danger"}>{status}</span>;
      default:
        return <span className={commonClass + "bg-dark"}>{status}</span>;
    }
  };

  if (response.isLoading || branches.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;
  if (branches.isError) return <ApiErrorModal response={branches} />;

  return (
    <ResponsiveContainerCard
      titleBar={() => (
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className="d-flex">
            {Icons.userManagement("text-primary fs-1 mb-1 me-4", {})}
            <div className="fs-2 fw-bold mb-1">
              <span className="text-danger">User</span> Management
            </div>
          </div>
          <div className="mb-1 d-flex">
            {branches.data.length > 0 && (
              <Select
                name={"Select"}
                containerClass={"me-3"}
                setValue={setSelectedBranch}
                options={[
                  ...["No Branch"],
                  ...branches.data.map((branch, id) => branch.branchName),
                ]}
              />
            )}
            <Link
              to={"/UserManagement/create"}
              className="btn btn-secondary float-end"
            >
              Add User
            </Link>
          </div>
        </div>
      )}
    >
      <table className="table">
        {getTableHead()}
        <tbody>
          {response?.data
            .filter((i) => {
              if (portal == "Brahmvidya") {
                return i.brahmvidyaPortalAccess != null;
              } else if (portal == "Education") {
                return i.educationPortalAccess != null;
              } else {
                return true;
              }
            })
            .filter(
              (i) =>
                selectedBranch === "No Branch" ||
                i?.branch?.branchName === selectedBranch
            )
            .map((data, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td className="d-flex align-items-center">
                  <Image
                    src={
                      Api.PROFILE_PICTURE_URL +
                      data?.profilePicture?.id +
                      data?.profilePicture?.ext
                    }
                    alt="profile"
                    className="rounded-circle me-3"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    icon={() =>
                      Icons.defaultUser("", { width: "100%", height: "100%" })
                    }
                  />
                  <div>
                    {data.name}
                    <p className="fs-11 text-muted">{data.post}</p>
                  </div>
                </td>

                {/* <td>{data.name} <p className='fs-11'>{data.post}</p></td> */}
                <td>{data.sUid}</td>
                <td>{data?.branch?.branchName}</td>
                <td>{data.email}</td>
                <td>{getStatusTag(data.status)}</td>
                <td>
                  <Link
                    to={`/UserManagement/${data.id}`}
                    className="btn btn-primary rounded-pill btn-sm px-3"
                  >
                    View Details
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </ResponsiveContainerCard>
  );
};

export default UserManagement;
