import React from "react";
import { Link } from "react-router-dom";
import { useGetBranchesQuery } from "../../services/branchSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modal/ApiErrorModal";
import TableContainerCard from "../../components/ui/TableContainerCard";
import ResponsiveContainerCard from "../../components/ui/ResponsiveContainerCard";
import Icons from "../../components/ui/Icons";
import Api from "../../constants/Api";
import Image from "../../components/ui/Image";

const BranchManagement = () => {
  const response = useGetBranchesQuery();
  const headers = [
    "ID",
    "Branch name",
    "GUID",
    "Main Branch",
    "Total users",
    "Active users",
    " Branch Status",
    "Actions",
  ];

  const getTableHead = () => (
    <thead>
      <tr>
        {headers.map((header) => (
          <th key={header} className="text-white sticky-top bg-primary">
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const getStatusTag = (status) => {
    const commonClass = "rounded-pill text-white fw-bold px-3 ";
    switch (status) {
      case "Active":
        return <span className={commonClass + "bg-success"}>{status}</span>;
      case "Suspended":
        return <span className={commonClass + "bg-warning"}>{status}</span>;
      case "Blocked":
        return <span className={commonClass + "bg-danger"}>{status}</span>;
      default:
        return <span className={commonClass + "bg-dark"}>{status}</span>;
    }
  };

  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  const branch = response.data;

  return (
    <ResponsiveContainerCard
      titleBar={() => (
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className="d-flex">
            {Icons.branchManagement("text-primary fs-1 mb-1 me-4", {})}
            <div className="fs-2 fw-bold mb-1">
              <span className="text-danger">Branch</span> Management
            </div>
          </div>
          <Link
            to={"/BranchManagement/create"}
            className="btn btn-primary float-end"
          >
            Add branch
          </Link>
        </div>
      )}
    >
      <table className="table">
        {getTableHead()}
        <tbody>
          {response?.data?.map((data, i) => (
            <tr key={i} className="text-nowrap ">
              <td>{data.id}</td>
              <td className="d-flex align-items-center">
                <Image
                  src={
                    Api.PROFILE_PICTURE_URL +
                    data?.profilePicture?.id +
                    data?.profilePicture?.ext
                  }
                  alt="profile"
                  className="rounded-circle me-3"
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  icon={() =>
                    Icons.defaultBranch("border rounded-circle border-3 ", {
                      width: "100%",
                      height: "100%",
                    })
                  }
                />
                {data.branchName}
              </td>
              {/* <td>{data.branchName}</td> */}
              <td>{data.branchId}</td>
              <td>{data.mainBranch.name} </td>
              <td>
                {data.users.length} <br />{" "}
                <p className="fs-11 text-muted">utility users</p>
              </td>
              <td>
                {data.users.filter((item) => item.status == "Active").length}
                <p className="fs-11 text-muted">utility users</p>
              </td>
              <td>{getStatusTag(data.status)}</td>
              <td>
                <Link
                  to={`/BranchManagement/${data.id}`}
                  className="btn btn-primary rounded-pill btn-sm px-3"
                >
                  View
                </Link>
                {/* <Link to={`/BranchManagement/${data.id}`} className='ms-2 btn btn-secondary rounded-pill btn-sm px-3' >Edit</Link> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ResponsiveContainerCard>
  );
};

export default BranchManagement;
