import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({ query: () => `/user`, keepUnusedDataFor: 0 }),
        getUser: builder.query({ query: (id) => `/user?id=${id}`, keepUnusedDataFor: 0 }),
        postUser: builder.mutation({ query: (body) => ({ url: `/user`, method: 'POST', body }) }),
        patchUser: builder.mutation({ query: ({ id, body }) => ({ url: `/user?id=${id}`, method: 'PATCH', body }) }),
        deleteUser: builder.mutation({ query: (id) => ({ url: `/user?id=${id}`, method: 'DELETE' }) }),
    }),
})


export const { useGetUserQuery, useGetUsersQuery, usePostUserMutation, usePatchUserMutation, useDeleteUserMutation } = extendedApiSlice
