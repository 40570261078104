import React from 'react'

const Bar = ({ label, children, containerClass }) => {

    const barClassName = "d-flex bg-secondary text-white justify-content-center py-1 text-uppercase mb-3"
    const containerClassName = "container px-3 pt-3 " + (containerClass ? containerClass : "")

    return (
        <>
            <div className={barClassName}>{label}</div>
            <div className={containerClassName}>{children}</div>
        </>
    )
}

export default Bar