import React, { useEffect, useState } from "react";
import SuccessModal from "../../components/modal/SuccessModal";
import ApiErrorModal from "../../components/modal/ApiErrorModal";
import {
  useGetAdminQuery,
  usePatchAdminMutation,
  usepatchAdminMutation,
} from "../../services/adminSlice";
import Check from "../../components/form/Check";
import Input from "../../components/form/Input";
import Bar from "../../components/form/Bar";
import Select from "../../components/form/Select";
import iconImg from "../../assets/images/icon.png";
import icon1Img from "../../assets/images/icon1.png";
import userPhoto from "../../assets/images/userPhoto.png";
import { PORTALS, POSTS, ROLES, TYPES } from "../../constants/Constants";
import ContainerCard from "../../components/ui/ContainerCard";
import ImageInput from "../../components/form/ImageInput";
import { useGetBranchesQuery } from "../../services/branchSlice";
import Icons from "../../components/ui/Icons";
import ResponsiveContainerCard from "../../components/ui/ResponsiveContainerCard";
import Loading from "../../components/ui/Loading";
import SelectWithLabels from "../../components/form/SelectWithLabels";
import Button from "../../components/form/Button";
import { Link, useParams } from "react-router-dom";
import AdminDeleteModal from "../../components/modal/AdminDeleteModal";
import Api from "../../constants/Api";

const EditAdmin = () => {
  const { id } = useParams();
  const adminRes = useGetAdminQuery(id);
  const branchesRes = useGetBranchesQuery();
  const [patchAdmin, patchAdminRes] = usePatchAdminMutation();
  const [activePortal, setActivePortal] = useState(PORTALS[0]);
  const [activeSubPortal, setActiveSubPortal] = useState(
    PORTALS[0].subPortals[0]
  );
  const [formEnabled, setFormEnabled] = useState(false);

  const [brahmvidyaAccessGranted, setBrahmvidyaAcccessGranted] =
    useState(false);
  const [educationAccessGranted, setEducationAcccessGranted] = useState(false);

  const activeBarClassName =
    "px-3 py-1 bg-secondary text-white rounded-top col";
  const inactiveBarClassName = "px-3 py-1 col";
  const consentLabel =
    "By checking this box I hereby give my consent for the Admin console grantal and that the user will abide by polices, terms and conditions established by the SGRS Utility Administrator. And that in any case of awry, SGRS UTILITY Administrator’s decision will be final for all settlements.";

  const renderCheck = (name, option, defaultValue) => (
    <Check
      name={name + option}
      label={option}
      defaultChecked={defaultValue}
      disabled={!formEnabled}
      containerClass={"me-3"}
    />
  );

  const renderPortal = (portal) => (
    <div
      className={
        activePortal.name === portal.name
          ? activeBarClassName
          : inactiveBarClassName
      }
      onClick={() => {
        setActivePortal(portal);
        setActiveSubPortal(portal.subPortals[0]);
      }}
    >
      {portal.name}
    </div>
  );

  const renderSubPortal = (subPortal) => (
    <div
      className={
        activeSubPortal.name === subPortal.name
          ? activeBarClassName
          : inactiveBarClassName
      }
      onClick={() => setActiveSubPortal(subPortal)}
    >
      {subPortal.name}
    </div>
  );

  const toCamelCase = (str) => {
    return (
      str
        // Split the string into words
        .split(" ")
        // Map each word to a function that capitalizes it (except the first word)
        .map((word, index) =>
          index === 0
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        // Join the words back into a single string
        .join("")
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity()) {
      let body = new FormData();

      if (form["Admin Photo"].files.length > 0) {
        body.append("profilePicture", form["Admin Photo"].files[0]);
      }

      body.append("name", form["Name"].value);
      body.append("sUid", form["SUID"].value);
      body.append("branchId", form["Branch"].value);
      body.append("email", form["Email"].value);
      body.append("phoneNumber", form["Phone No"].value);
      body.append("post", form["Post"].value);

      body.append("userName", form["Username"].value);
      body.append("password", form["Password"].value);
      body.append("status", form["Status"].value);

      body.append(
        "brahmvidhyaGrantAccess",
        form["Brahmvidhya Grant Access"]?.checked
      );
      body.append(
        "brahmvidhyaSandhyaSabha",
        form["Brahmvidhya Sandhya Sabha"]
          ? form["Brahmvidhya Sandhya Sabha"].checked
          : false
      );
      body.append(
        "brahmvidhyaCoursebooks",
        form["Brahmvidhya Coursebooks"]
          ? form["Brahmvidhya Coursebooks"].checked
          : false
      );
      body.append(
        "brahmvidhyaAssignments",
        form["Brahmvidhya Assignments"]
          ? form["Brahmvidhya Assignments"].checked
          : false
      );
      body.append(
        "brahmvidhyaDownloadables",
        form["Brahmvidhya Downloadables"]
          ? form["Brahmvidhya Downloadables"].checked
          : false
      );
      body.append(
        "brahmvidhyaTraining",
        form["Brahmvidhya Training"]
          ? form["Brahmvidhya Training"].checked
          : false
      );
      body.append(
        "brahmvidhyaICGSPinwheel",
        form["Brahmvidhya ICGS Pinwheel"]
          ? form["Brahmvidhya ICGS Pinwheel"].checked
          : false
      );

      body.append(
        "educationGrantAccess",
        form["Education Grant Access"]?.checked
      );
      body.append(
        "educationCirculars",
        form["Education Circulars"]
          ? form["Education Circulars"].checked
          : false
      );
      body.append(
        "educationSchool",
        form["Education School"] ? form["Education School"].checked : false
      );
      body.append(
        "educationHostel",
        form["Education Hostel"] ? form["Education Hostel"].checked : false
      );
      body.append(
        "educationEducation",
        form["Education Education"]
          ? form["Education Education"].checked
          : false
      );
      body.append(
        "educationKanyaGurukul",
        form["Education Kanya Gurukul"]
          ? form["Education Kanya Gurukul"].checked
          : false
      );
      body.append(
        "educationDownloadables",
        form["Education Downloadables"]
          ? form["Education Downloadables"].checked
          : false
      );
      body.append(
        "educationArchives",
        form["Education Archives"] ? form["Education Archives"].checked : false
      );

      body.append("pin", form["Pin"].value);

      patchAdmin({ id, body }).then((res) => {
        if (!res.error) {
          adminRes.refetch();
          form.classList.remove("was-validated");
        }
      });
    } else form.classList.add("was-validated");
  };

  useEffect(() => {
    if (adminRes.isSuccess && adminRes.data) {
      setBrahmvidyaAcccessGranted(adminRes.data?.brahmvidyaPortalAccess);
      setEducationAcccessGranted(adminRes.data?.educationPortalAccess);
    }
  }, [adminRes.isSuccess]);

  if (branchesRes.isLoading || adminRes.isLoading) return <Loading />;
  if (branchesRes.isError) return <ApiErrorModal response={branchesRes} />;
  if (adminRes.isError) return <ApiErrorModal response={adminRes} />;

  const branches = branchesRes.data.map((branch) => ({
    label: branch?.id + " " + branch?.branchName,
    value: branch?.id,
  }));
  const admin = adminRes.data;

  return (
    <ResponsiveContainerCard
      titleBar={() => (
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className="d-flex">
            {Icons.adminManagement("text-primary fs-1 mb-1 me-4", {})}
            <div className="fs-2 fw-bold mb-1">
              <span className="text-danger">
                {formEnabled ? "Edit" : "View"}
              </span>{" "}
              Admin
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Check
              name={"Check to edit"}
              setChecked={setFormEnabled}
              containerClass={"me-3"}
              required
            />
            <AdminDeleteModal id={id} />
            <Link to={-1} className="btn btn-primary">
              Back
            </Link>
          </div>
        </div>
      )}
    >
      {patchAdminRes.isError && <ApiErrorModal response={patchAdminRes} />}
      {patchAdminRes.isSuccess && (
        <SuccessModal
          label="Admin Created"
          message="Admin successfully created."
          to={{ link: "/AdminManagement", text: "Go to Admin Management" }}
        />
      )}

      <form onSubmit={onSubmit} noValidate>
        <Bar label={"Primary Details"}>
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-grow-1 mb-2 text-center text-lg-start">
              <ImageInput
                name={"Admin Photo"}
                defaultValue={
                  Api.PROFILE_PICTURE_URL +
                  admin?.profilePicture?.id +
                  admin?.profilePicture?.ext
                }
                disabled={!formEnabled}
              />
            </div>

            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <Input
                  name={"Name"}
                  defaultValue={admin.name}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"SUID"}
                  defaultValue={admin.sUid}
                  disabled={!formEnabled}
                  required
                />
                <SelectWithLabels
                  name={"Branch"}
                  options={branches}
                  defaultValue={admin.branchId}
                  disabled={!formEnabled}
                />
                <Input
                  name={"Email"}
                  type={"email"}
                  defaultValue={admin.email}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Phone No"}
                  defaultValue={admin.phoneNumber}
                  disabled={!formEnabled}
                  required
                />
                <Input
                  name={"Post"}
                  defaultValue={admin.post}
                  disabled={!formEnabled}
                  required
                />
                {/* <Select name={"Post"} options={POSTS} defaultValue={admin.post} disabled={!formEnabled} /> */}
              </div>
            </div>
          </div>
        </Bar>

        <Bar label={"Credentials"}>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
            <Input
              name={"Username"}
              defaultValue={admin.userName}
              disabled={!formEnabled}
              required
            />
            <Input
              name={"Password"}
              type={"password"}
              defaultValue={admin.password}
              disabled={!formEnabled}
              required
            />
            <Select
              name={"Status"}
              options={["Active", "Suspended", "Blocked"]}
              defaultValue={admin.status}
              disabled={!formEnabled}
            />
          </div>
        </Bar>

        <Bar label={"Select portal"}>
          <div className="row row-cols-3 row-cols-md-3 row-cols-lg-4 g-3 fs-13">
            {PORTALS.map(renderPortal)}
          </div>
        </Bar>

        <Bar label={"Select sub portal"}>
          <div className="row row-cols-3 row-cols-md-3 row-cols-lg-4 g-3 fs-13">
            {activePortal.subPortals.map(renderSubPortal)}
          </div>
        </Bar>

        <Bar label={"Access management"} containerClass={"flex-column"}>
          <div
            className={
              activeSubPortal.name === "ICGS Brahmvidhya"
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={"Brahmvidhya Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              checked={brahmvidyaAccessGranted}
              setChecked={setBrahmvidyaAcccessGranted}
              disabled={!formEnabled}
            />
            {brahmvidyaAccessGranted &&
              PORTALS[0].subPortals[0].accesses.map((option) =>
                renderCheck(
                  "Brahmvidhya ",
                  option,
                  admin.brahmvidyaPortalAccess &&
                    admin.brahmvidyaPortalAccess[toCamelCase(option)]
                )
              )}
          </div>
          <div
            className={
              activeSubPortal.name === "ICGS Education"
                ? "d-flex flex-wrap mb-3"
                : "d-none"
            }
          >
            <Check
              name={"Education Grant Access"}
              label={"Grant Access"}
              containerClass={"me-3"}
              checked={educationAccessGranted}
              setChecked={setEducationAcccessGranted}
              disabled={!formEnabled}
            />
            {educationAccessGranted &&
              PORTALS[0].subPortals[1].accesses.map((option) =>
                renderCheck(
                  "Education ",
                  option,
                  admin.educationPortalAccess &&
                    admin.educationPortalAccess[toCamelCase(option)]
                )
              )}
          </div>
        </Bar>

        <Bar label={"CONSENT"} containerClass={"flex-column align-items-start"}>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  mb-3">
            <Input name={"Pin"} disabled={!formEnabled} required />
          </div>

          <div className="row row-cols-1 align-items-center justify-content-end px-3 mb-3 g-3">
            <Check
              name={"consent"}
              label={consentLabel}
              containerClass={"col col-lg-10"}
              disabled={!formEnabled}
              required
            />

            <Button
              className="col col-lg-2 btn btn-secondary rounded-pill text-nowrap"
              res={patchAdminRes}
              loadingLabel={"Updating"}
              disabled={!formEnabled}
            >
              Update Admin
            </Button>
          </div>
        </Bar>
      </form>
    </ResponsiveContainerCard>
  );
};

export default EditAdmin;
