import React from 'react'
import ErrorModal from './ErrorModal';
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../../redux/authSlice';

export default function ApiErrorModal({ response }) {

    const error = response.isError ? response.error : null
    const dispatch = useDispatch()

    const signOut = () => {
        dispatch(setAuthToken(null))
        return;
    }

    if (error) {
        if (error.status === "FETCH_ERROR") return <ErrorModal label={"No Internet"} message={"Kindly check your internet connection."} />
        else if (error.status === 401) signOut()
        else if (error.status === 400) return <ErrorModal label={"Bad Request"} message={error.data?.message} />
        else if (error.data?.message) return <ErrorModal label={"Bad Request"} message={error.data?.message} />
        else return <ErrorModal label={"Error"} message={"Something went wrong"} />
    }

    return;

}
