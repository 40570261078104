import React, { useState } from 'react'
import { usePostMainBranchMutation } from '../../services/mainBranchSlice';
import ApiErrorModal from '../../components/modal/ApiErrorModal';
import Icons from '../../components/ui/Icons';
import Bar from '../../components/form/Bar';
import Input from '../../components/form/Input';
import Check from '../../components/form/Check';
import Select from '../../components/form/Select';
import SuccessModal from '../../components/modal/SuccessModal';
import ResponsiveContainerCard from '../../components/ui/ResponsiveContainerCard';
import { Link } from 'react-router-dom';
import Button from '../../components/form/Button';
import ImageInput from '../../components/form/ImageInput';

const CreateMainBranch = () => {


  const [postBranch, postBranchRes] = usePostMainBranchMutation();

  const labelClass = "me-3 text-nowrap"
  const consentLabel = "By checking this box I hereby give my consent for the branch registry and that the branch will abide by polices, terms and conditions for continuing any utility service provided by the SGRS Utility Administrator. And that in any case of awry, SGRS UTILITY Administrator’s decision will be final for all settlements."


  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target

    if (form.checkValidity()) {

      let formData = new FormData();

      formData.append("profilePicture", form["Branch Photo"].files[0]);

      formData.append('name', form['Branch Name'].value);
      formData.append('branchId', form['Branch ID'].value);
      formData.append('dateEstablished', form['Date Established'].value);
      formData.append('address', form['Address'].value);
      formData.append('state', form['State'].value);
      formData.append('country', form['Country'].value);
      formData.append('externalLink', form['External Link'].value);
      formData.append('status', form['Status'].value);

    


      formData.append('consenterName', form['Consenter Name'].value);
      formData.append('pin', form['Pin'].value);


      postBranch(formData).then((res) => {
        if (!res.error) {
          form.reset()
          form.classList.remove('was-validated')
        }
      })

    }
    else form.classList.add('was-validated')

  }



  return (
    <ResponsiveContainerCard
      titleBar={() =>
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className='d-flex'>
            {Icons.mainBranch("text-primary fs-1 mb-1 me-4", {})}
            <div className='fs-2 fw-bold mb-1'>
              <span className='text-danger'>MainBranch</span> Management
            </div>
          </div>
        </div>
      }
    >

      {postBranchRes.isError && <ApiErrorModal response={postBranchRes} />}
      {postBranchRes.isSuccess && <SuccessModal label="Branch Created" message="Branch successfully created." to={{ link: "/MainBranchManagement", text: "Go to Branch Management" }} />}

      <form className='' onSubmit={onSubmit} noValidate>

        <Bar label={"Primary Details"} >
          <div className="d-flex  flex-column flex-lg-row">
            <div className="flex-grow-1 mb-2 text-center text-lg-start">
              <ImageInput name={"Branch Photo"}  />

            </div>
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <Input name={"Branch Name"} required />
                <Input name={"Branch ID"} placeholder={"Ex: G01"} required />
                <Input name={"Date Established"} type={"date"} placeholder={"DD/MM/YYYY"} required />

                <Input name={"Address"} placeholder={""} required />
                <Input name={"State"} placeholder={""} required />
                <Input name={"Country"} placeholder={""} required />

                <Input name={"External Link"} placeholder={"abc/anb.com"} />
                <Select name={"Status"} options={["Active", "Suspended", "Blocked"]} labelClass={labelClass} />
              
              </div>
            </div>
          </div>
        </Bar>

     

  

        <Bar label={"CONSENT"} containerClass={"flex-column py-3 align-items-start"}>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3  mb-3">
            <Input name={"Consenter Name"} required />
            <Input name={"Pin"} required />
          </div>

          <div className="row row-cols-1 align-items-center justify-content-end px-3 mb-3 g-3">
            <Check name={"consent"} label={consentLabel} containerClass={"col col-lg-10"} required />

            <Button
              className='col col-lg-2 btn btn-secondary rounded-pill text-nowrap'
              res={postBranchRes}
              loadingLabel={"Registering"}
            >
              Register Branch
            </Button>

          </div>
        </Bar>

      </form>

    </ResponsiveContainerCard>
  )

}

export default CreateMainBranch
