import React from 'react'
import { useLocation } from 'react-router-dom'
import SignOut from './SignOut'
import { GiHamburgerMenu } from "react-icons/gi";
import ProfileDropdown from './ProfileDropdown';

const Headbar = () => {

  const { pathname } = useLocation()

  // const blackListedPaths = ["/ICGSPortals", "/ResearchPortals", "/SPMKPortals"]

  // const isNotVisible = blackListedPaths.includes(pathname)

  // if (isNotVisible) return ""

  return (
    <div className='bg-light p-2 shadow d-flex justify-content-between'>
      <button className="d-lg-none btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarOffCanvas" aria-controls="sidebarOffCanvas">
        <GiHamburgerMenu />
      </button>
      <ProfileDropdown />
      {/* <SignOut /> */}
    </div>
  )
}

export default Headbar