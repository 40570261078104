import React, { useEffect, useState } from 'react'
import ContainerCard from '../../components/ui/ContainerCard'
import Icons from '../../components/ui/Icons'
import { Link, useNavigate } from 'react-router-dom'
import ApiErrorModal from '../../components/modal/ApiErrorModal'
import Loading from '../../components/ui/Loading'
import { useAdminInsightsQuery } from '../../services/insightSlice'
import { useGetRemindersQuery } from '../../services/reminderSlice'
import { transformDateOnly } from '../../utility/date'
import PostReminderModal from '../../components/modal/PostReminderModal'
import ViewEditReminderModal from '../../components/modal/ViewEditReminderModal'
import Check from '../../components/form/Check'
import { useGetAdminsQuery, usePatchToggleBramvidyaMutation, usePatchToggleEducationMutation } from '../../services/adminSlice'
import SuccessModal from '../../components/modal/SuccessModal'
import { useGetUsersQuery } from '../../services/userSlice'
import { useGetInfosQuery } from '../../services/infoSlice'
import ViewEditInfoModal from '../../components/modal/ViewEditInfoModal'
import { useGenerateTokenMutation } from '../../services/authSlice'
import Api from '../../constants/Api'

const COLOR = {
  BLUE: "#01519B",
  RED: "#A01F1F",
  GREEN: "#1FA024",
  VIOLET: "#79008D"
}

const BrahmvidyaPortal = () => {

  const navigate = useNavigate()
  const res = useAdminInsightsQuery()
  const remindersRes = useGetRemindersQuery()



  const getAdmins = useGetAdminsQuery()
  const getUsers = useGetUsersQuery()

  const getInfo = useGetInfosQuery();


  const [toggleBrahamvidya, toggleBrahamvidyaResponse] = usePatchToggleBramvidyaMutation()
  const [generateToken, generateTokenRes] = useGenerateTokenMutation();

  const [userToken, setUserToken] = useState(null);
  const [adminToken, setAdminToken] = useState(null);

  const pillBtnClassName = "btn rounded-pill mx-2 px-3 py-1 text-white mb-0"

  const handleToggleChange = (isBrahmvidyaOpen) => {
    toggleBrahamvidya({ isBrahmvidyaOpen });
  };



  useEffect(() => {
    // Make an API call to generate the token for the user

    if (getUsers.isSuccess && getAdmins.isSuccess) {

      const filteredAdmin = getAdmins?.data?.filter((item) => (item.id === 1))
      const filteredUser = getUsers?.data?.filter((item) => (item.id === 1))

      console.log(getUsers?.data)
      console.log(filteredUser)
      const fetchUserToken = async () => {
        try {
          const response = await generateToken({ id: filteredUser[0].id, type: 'USER' })
          setUserToken(response.data);
        } catch (error) {
          console.error('Error fetching user token:', error);
        }
      };

      const fetchAdminToken = async () => {
        try {
          const response = await generateToken({ id: filteredAdmin[0].id, type: 'ADMIN' })
          setAdminToken(response.data);
        } catch (error) {
          console.error('Error fetching user token:', error);
        }
      };

      fetchUserToken();
      fetchAdminToken();

    }


  }, [getUsers.isSuccess, getAdmins.isSuccess]);



  if (res.isLoading || remindersRes.isLoading || getAdmins.isLoading || getUsers.isLoading || getInfo.isLoading || generateTokenRes.isLoading) return <Loading />
  if (res.isError) return <ApiErrorModal response={res} />
  if (remindersRes.isError) return <ApiErrorModal response={remindersRes} />
  if (getAdmins.isError) return <ApiErrorModal response={getAdmins} />
  if (getUsers.isError) return <ApiErrorModal response={getUsers} />
  if (getInfo.isError) return <ApiErrorModal response={getInfo} />
  if (generateTokenRes.isError) return <ApiErrorModal response={generateTokenRes} />
  // if (res.isLoading || remindersRes.isLoading || getAdmins.isLoading || getUsers.isLoading || getInfo.isLoading) return <Loading />





  return (
    <div className="p-4 h-100 ">
      {
        (toggleBrahamvidyaResponse.isSuccess) && <SuccessModal message={toggleBrahamvidyaResponse.data.message} />
      }
      <div className="card p-3 bg-white bg-opacity-25 border-0 shadow rounded-3 h-100 overflow-hidden" >

        <div className="d-flex align-items-center mb-5">
          <div className='fs-3 fw-bold text-blue me-auto'><span className='text-danger'>Brahmvidya</span> Portal</div>
          {
            (userToken) ?
              <Link className={pillBtnClassName} style={{ backgroundColor: COLOR.BLUE }} to={`${Api.BRAHMVIDHYA_USER_URL}/token/${userToken}`} target="_blank">User Interface</Link>
              :
              <Link className={pillBtnClassName} style={{ backgroundColor: COLOR.BLUE }} to={Api.BRAHMVIDHYA_USER_URL} target="_blank">User Interface</Link>

          }
          {
            (adminToken) ?
              < Link className={pillBtnClassName} style={{ backgroundColor: COLOR.RED }} to={`${Api.BRAHMVIDHYA_ADMIN_URL}token/${adminToken}`} target="_blank">Admin Console</Link>
              :
              < Link className={pillBtnClassName} style={{ backgroundColor: COLOR.RED }} to={Api.BRAHMVIDHYA_ADMIN_URL} target="_blank">Admin Console</Link>

          }


          <Link className={pillBtnClassName} style={{ backgroundColor: COLOR.GREEN }} to={Api.BRAHMVIDHYA_BACKEND_URL} target="_blank">Backend Console</Link>
        </div>

        <div className="row gy-5 gx-4  mb-4">

          <div className="col-7 d-flex flex-column align-items-center">
            <div className="card d-flex w-100 text-center rounded-5 overflow-hidden shadow ">

              <div className="d-flex align-items-center justify-content-center mb-2">
                <span className='bg-primary rounded-bottom-4 text-white px-3 py-2'>Portal User Summary</span>
              </div>

              {/* <div className='bg-danger pb-4'><span className=' rounded-bottom-4  bg-secondary text-white'>Portal user summary</span></div> */}

              <div className='d-flex mt-2 '>
                <div className='flex-grow-1'>
                  <div className='display-3'>{res.data.brahmvidyaAdminCount}</div>
                  <div className='mb-2'>Admin users</div>
                  <div className='text-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/AdminManagement/portal/Brahmvidya')}>Manage Admins</div>
                </div>

                <div className='flex-grow-1'>
                  <div className='display-3'>{res.data.brahmvidyaUserCount}</div>
                  <div className='mb-2'>Utility users</div>
                  <div className='text-white border-start border-end border-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/UserManagement/portal/Brahmvidya')}>Manage Users</div>
                </div>

                <div className='flex-grow-1'>
                  <div className='display-3'>{res.data.branchCount}</div>
                  <div className='mb-2'>Branches</div>
                  <div className='text-white p-3' style={{ backgroundColor: COLOR.VIOLET }} onClick={() => navigate('/BranchManagement')}>Manage Branches</div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-5 d-flex flex-column align-items-start">
            <div className="flex-grow-1 card  w-100 text-center  rounded-5  overflow-hidden shadow">
              <div className="d-flex align-items-center justify-content-center ">
                <span className='bg-primary rounded-bottom-4 text-white px-3 py-2'>Service Activation</span>
              </div>

              <div className="d-flex gap-5 mt-4 px-4">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    defaultChecked={res?.data?.serviceActivation?.isBrahmvidyaOpen}
                    onChange={() => handleToggleChange(!res.data?.serviceActivation?.isBrahmvidyaOpen)}
                  />
                  <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                    Web Portal
                  </label>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div className="row flex-grow-1 ">
          <div className="col-7  d-flex flex-column align-items-center">
            <div className="card w-100 h-100 text-center rounded-5 overflow-hidden shadow">
              <div className="d-flex align-items-center justify-content-center ">
                <span className='bg-primary rounded-bottom-4 text-white px-3 py-2'>Portal Status Report</span>
              </div>
              <div className="p-3 pt-1 hide-scroll">
                <div className="d-flex align-items-center justify-content-end mb-3">
                  <PostReminderModal refetch={remindersRes.refetch} subPortal />
                </div>

                <div className="overflow-auto h-100" style={{ maxHeight: 310 }}>


                  {remindersRes.data.filter(i => i.portal === "ICGS Brahmvidya").map(item =>
                    <div className="d-flex justify-content-between fs-13">
                      <div>{item.title}</div>
                      <div style={{ color: COLOR.GREEN }}>{transformDateOnly(item.pointOne)}</div>
                      <ViewEditReminderModal refetch={remindersRes.refetch} id={item.id} subPortal />
                    </div>
                  )}

                </div>
              </div>

            </div>
          </div>


          <div className="col-5 d-flex flex-column align-items-start ">
            {/* <div className='rounded-end-pill rounded-top-pill bg-secondary text-white py-2 px-5' style={{ marginBottom: -20, zIndex: 5 }}>Critical Information</div> */}
            <div className="flex-grow-1 card w-100 text-start overflow-hidden shadow rounded-5 hide-scroll" >
              <div className="d-flex align-items-center justify-content-center mb-2">
                <span className='bg-primary rounded-bottom-4 text-white px-4 py-2'>Critical Information</span>
              </div>
              <div className='text-end px-3'>
                {getInfo.data.filter((i) => i.type === 'Brahmvidya').length > 0 ?
                  <ViewEditInfoModal refetch={getInfo.refetch} id={getInfo.data.filter((i) => i.type === 'Brahmvidya')[0].id} subPortal={'Brahmvidya'} isCreate={false} />
                  :
                  <ViewEditInfoModal refetch={getInfo.refetch} id={4} subPortal={'Brahmvidya'} isCreate={true} />
                }
              </div>
              <div className="px-4 overflow-auto" style={{ maxHeight: 310 }}>
                {getInfo.data
                  .filter((i) => i.type === 'Brahmvidya')
                  .map((i, index) => (
                    <div key={index} dangerouslySetInnerHTML={{ __html: i.content }} />
                  ))}

              </div>

            </div>

          </div>


        </div>


      </div >
    </div >
  )


}
export default BrahmvidyaPortal