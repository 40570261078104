import React from 'react'
import { useDeleteUserMutation } from '../../services/userSlice'
import { useNavigate } from 'react-router-dom'
import Button from '../form/Button'
import { getApiErrorMessage } from '../../utility/errorMessages'

const UserDeleteModal = ({ id }) => {

    const navigate = useNavigate()
    const [deleteUser, deleteUserRes] = useDeleteUserMutation()

    const onDelete = () => {
        deleteUser(id).then((res) => {
            if (!res.error) {
                document.getElementById('userDeleteModalCloseBtn').click()
                navigate(-1)
            }
        })
    }

    return (
        <>
            <button type="button" className="btn btn-danger text-white me-3" data-bs-toggle="modal" data-bs-target="#userDeleteModal">
                Delete
            </button>

            <div className="modal fade" id="userDeleteModal" tabIndex="-1" aria-labelledby="userDeleteModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="userDeleteModalLabel">User Delete</h1>
                            <button id="userDeleteModalCloseBtn" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Do you really want to delete user?
                        </div>
                        <div className="modal-footer">
                            {deleteUserRes.isError &&
                                <div className='me-auto fw-bold text-danger'>
                                    {getApiErrorMessage(deleteUserRes).label}: {getApiErrorMessage(deleteUserRes).message}
                                </div>
                            }
                            <button type="button" className="btn btn-success" data-bs-dismiss="modal">No</button>
                            <Button
                                className="btn btn-danger text-white"
                                type="button"
                                onClick={onDelete}
                                res={deleteUserRes}
                                loadingLabel={"Deleting"}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDeleteModal