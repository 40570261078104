import React, { Children } from 'react'

const Input = ({ name, placeholder, type, labelName, labelClass, containerClass, inputClass, ...attributes }) => {

    const containerClassName = "d-flex align-items-center col " + (containerClass ? containerClass : "")
    const labelClassName = "fw-bold fs-13 me-3 text-nowrap " + (labelClass ? labelClass : "")
    const inputClassName = "form-control form-control-sm rounded-pill px-3 bg-info shadow-sm " + (inputClass ? inputClass : "")
    const inputType = type ? type : "text"

    return (
        <div className={containerClassName}>
            <label htmlFor={name} className={labelClassName}>{labelName ? labelName : name}</label>
            <input
                type={inputType} className={inputClassName}
                id={name} name={name} placeholder={placeholder ? placeholder : name}
                {...attributes}
            />

        </div>
    )
}

export default Input
