import React from 'react'

const Check = ({ name, label, labelClass, containerClass, inputClass, setChecked, ...attributes }) => {

    const containerClassName = "form-check " + (containerClass ? containerClass : "")
    const labelClassName = "form-check-input " + (labelClass ? labelClass : "")
    const inputSelectClassName = "form-check-label fs-13 " + (inputClass ? inputClass : "")

    const onChange = (e) => setChecked && setChecked(e.target.checked)

    return (
        <div className={containerClassName}>
            <input
                className={labelClassName}
                type="checkbox"
                value=""
                id={name}
                name={name}
                onChange={onChange}
                {...attributes}
            />
            <label className={inputSelectClassName} htmlFor={name}>{label ? label : name}</label>
        </div>
    )
}

export default Check