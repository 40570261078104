import React from 'react'

const Button = ({ res, children, loadingLabel, ...attributes }) => {

    if (res?.isLoading) {
        return (
            <button {...attributes} type='button' disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {loadingLabel}...
            </button>
        )
    }

    return (
        <button {...attributes} >{children}</button>
    )

}

export default Button