import React, { useState } from 'react'
import { usePostBranchMutation } from '../../services/branchSlice';
import ApiErrorModal from '../../components/modal/ApiErrorModal';
import Icons from '../../components/ui/Icons';
import Bar from '../../components/form/Bar';
import Input from '../../components/form/Input';
import Check from '../../components/form/Check';
import Select from '../../components/form/Select';
import SuccessModal from '../../components/modal/SuccessModal';
import ResponsiveContainerCard from '../../components/ui/ResponsiveContainerCard';
import { Link } from 'react-router-dom';
import Button from '../../components/form/Button';
import ImageInput from '../../components/form/ImageInput';
import { useGetMainBranchesQuery } from '../../services/mainBranchSlice';
import Loading from '../../components/ui/Loading';
import SelectWithLabels from '../../components/form/SelectWithLabels';

const CreateBranch = () => {

  const response = useGetMainBranchesQuery()


  const [postBranch, postBranchRes] = usePostBranchMutation();
  const [hostelFacility, setHostelFacility] = useState("Yes")
  const [branchType, setBranchType] = useState("Day School")

  const labelClass = "me-3 text-nowrap"
  const consentLabel = "By checking this box I hereby give my consent for the branch registry and that the branch will abide by polices, terms and conditions for continuing any utility service provided by the SGRS Utility Administrator. And that in any case of awry, SGRS UTILITY Administrator’s decision will be final for all settlements."

  const schooDetailsCondition = (type) => (type === "Day School" || type === "Gurukul" || type === "Kanya Gurukul")

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target

    if (form.checkValidity()) {



      let formData = new FormData();

      formData.append("profilePicture", form["Branch Photo"].files[0]);

      formData.append('branchName', form['Branch Name'].value);
      formData.append('branchId', form['Branch ID'].value);
      formData.append('dateEstablished', form['Date Established'].value);
      formData.append('address', form['Address'].value);
      formData.append('state', form['State'].value);
      formData.append('country', form['Country'].value);
      formData.append('mainBranchId', form['Main Branch'].value);
      formData.append('externalLink', form['External Link'].value);
      formData.append('status', form['Status'].value);
      formData.append('branchType', form['Branch Type'].value);

      if (form['School Type']) {
        formData.append('schoolType', form['School Type'].value);
        formData.append('gradeLevel', form['School Grade Levels'].value);
        formData.append('currentBoard', form['Current Board'].value);
        formData.append('medium', form['Medium'].value);
        formData.append('affiliationDate', form['Affiliation Date'].value);
        formData.append('accreditation', form['Accreditations'].value);
        formData.append('studentCapacity', form['School Student Capacity'].value);
        formData.append('hostelFacility', form['Hostel Facility'].value === 'Yes');
      }

      if (form['Hostel Type']) {
        formData.append('hostelType', form['Hostel Type'].value);
        formData.append('hostelGradeLevel', form['Hostel Grade Levels'].value);
        formData.append('hostelStudentCapacity', form['Hostel Student Capacity'].value);
      }

      formData.append('consenterName', form['Consenter Name'].value);
      formData.append('pin', form['Pin'].value);


      postBranch(formData).then((res) => {
        if (!res.error) {
          form.reset()
          form.classList.remove('was-validated')
        }
      })

    }
    else form.classList.add('was-validated')

  }




  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  const branches = response.data.map((branch) => ({ label: branch.id + " " + branch.name, value: branch.id }))

  return (
    <ResponsiveContainerCard
      titleBar={() =>

        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-3 px-5">
          <div className='d-flex'>
            {Icons.branchManagement("text-primary fs-1 mb-1 me-4", {})}
            <div className='fs-2 fw-bold mb-1'>
              <span className='text-danger'>Branch</span> Form
            </div>
          </div>
          <Link to={-1} className="btn btn-primary">Back</Link>
        </div>
      }
    >

      {postBranchRes.isError && <ApiErrorModal response={postBranchRes} />}
      {postBranchRes.isSuccess && <SuccessModal label="Branch Created" message="Branch successfully created." to={{ link: "/BranchManagement", text: "Go to Branch Management" }} />}

      <form className='' onSubmit={onSubmit} noValidate>

        <Bar label={"Primary Details"} >
          <div className="d-flex  flex-column flex-lg-row">
            <div className="flex-grow-1 mb-2 text-center text-lg-start">
              <ImageInput name={"Branch Photo"} />

            </div>
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <Input name={"Branch Name"} required />
                <Input name={"Branch ID"} placeholder={"Ex: G01"} required />
                <Input name={"Date Established"} type={"date"} placeholder={"DD/MM/YYYY"} required />

                <Input name={"Address"} placeholder={""} required />
                <Input name={"State"} placeholder={""} required />
                <Input name={"Country"} placeholder={""} required />

                <SelectWithLabels name={"Main Branch"} options={branches} required />
                <Input name={"External Link"} placeholder={"abc/anb.com"} />
                <Select name={"Status"} options={["Active", "Suspended", "Blocked"]} labelClass={labelClass} />
                <Select name={"Branch Type"} options={["Gurukul", "Kanya Gurukul", "Day School", "Mandir", "Mahila Mandir"]} labelClass={labelClass} setValue={setBranchType} />

              </div>
            </div>
          </div>
        </Bar>

        {
          schooDetailsCondition(branchType) &&
          <Bar label={"School details"} >
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
              <Input name={"School Type"} value={branchType === "Kanya Gurukul" ? "Girls" : "Boys"} disabled />
              <Input name={"School Grade Levels"} labelName={"Grade Levels"} placeholder={"1-12"} />

              <Input name={"Current Board"} placeholder={"Ex: CBSE"} />
              <Input name={"Medium"} placeholder={"Language"} />
              <Input name={"Affiliation Date"} type={"date"} placeholder={"DD/MM/YYYY"} />


              <Input name={"Accreditations"} placeholder={"Facilitation if any"} />
              <Input name={"School Student Capacity"} labelName={"Student Capacity"} placeholder={"500"} />
              <Select name={"Hostel Facility"} options={["Yes", "No"]} setValue={setHostelFacility} labelClass={labelClass} />
            </div>
          </Bar>
        }

        {
          (schooDetailsCondition(branchType) && hostelFacility === "Yes") &&
          <Bar label={"Hostel facility"} >
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
              <Input name={"Hostel Type"} value={branchType === "Kanya Gurukul" ? "Girls" : "Boys"} disabled />
              <Input name={"Hostel Grade Levels"} labelName={"Grade Levels"} placeholder={"6-10"} />
              <Input name={"Hostel Student Capacity"} labelName={"Student Capacity"} placeholder={"500"} />
            </div>
          </Bar>
        }

        <Bar label={"CONSENT"} containerClass={"flex-column py-3 align-items-start"}>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3  mb-3">
            <Input name={"Consenter Name"} required />
            <Input name={"Pin"} required />
          </div>

          <div className="row row-cols-1 align-items-center justify-content-end px-3 mb-3 g-3">
            <Check name={"consent"} label={consentLabel} containerClass={"col col-lg-10"} required />

            <Button
              className='col col-lg-2 btn btn-secondary rounded-pill text-nowrap'
              res={postBranchRes}
              loadingLabel={"Registering"}
            >
              Register Branch
            </Button>

          </div>
        </Bar>

      </form>

    </ResponsiveContainerCard>
  )

}

export default CreateBranch
