
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReminders: builder.query({ query: () => `/reminder`, keepUnusedDataFor: 0 }),
        getReminder: builder.query({ query: (id) => `/reminder?id=${id}`, keepUnusedDataFor: 0 }),
        PostReminder: builder.mutation({ query: (body) => ({ url: `/reminder`, method: 'POST', body }) }),
        patchReminder: builder.mutation({ query: ({ id, body }) => ({ url: `/reminder?id=${id}`, method: 'PATCH', body }) }),
        deleteReminder: builder.mutation({ query: (id) => ({ url: `/reminder?id=${id}`, method: 'DELETE' }) }),
    }),
})


export const { useGetReminderQuery, useGetRemindersQuery, usePostReminderMutation, usePatchReminderMutation, useDeleteReminderMutation } = extendedApiSlice
