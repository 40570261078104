import React, { useState } from 'react'
import logoImg from "../../assets/images/logo.png"
import { useSignInMutation } from '../../services/authSlice'
import ApiErrorModal from '../modal/ApiErrorModal'
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../../redux/authSlice'

const SignInForm = () => {
    const dispatch = useDispatch();
    const [signIn, signInResponse] = useSignInMutation()
    const submitBtnClassname = "btn btn-primary align-self-start rounded-pill px-5";

    const onSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        if (form.checkValidity()) {
            const body = {
                email: form['email'].value,
                password: form['password'].value,
            };

            signIn(body).then((res) => {
                if (!res.error) {
                    dispatch(setAuthToken(res.data.token))
                }
            })
        }
        form.classList.add('was-validated')

    };

    return (
        <form onSubmit={onSubmit} className='d-flex flex-column h-100 bg-primary bg-opacity-10 justify-content-center align-items-center text-center p-3 p-lg-5' noValidate>

            {signInResponse.isError && <ApiErrorModal response={signInResponse} />}

            <img src={logoImg} alt="" className='object-fit-contain mb-3' style={{ height: 180 }} />
            <div className='mb-4 fw-bold'>Integrated Super administrative console for SGRS Utility Applications</div>
            <h4 className='mb-3'>Login to your Account</h4>

            <input type="email" className="form-control rounded-pill mb-3" name='email' placeholder="Email" required />
            <input type="password" className="form-control rounded-pill mb-3" name='password' placeholder="Password" required />

            <div className="d-flex justify-content-between mb-3 w-100">

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Remember Me
                    </label>
                </div>

                <div className='fw-bold'>Forgot Password?</div>

            </div>

            {
                signInResponse.isLoading
                    ?
                    <button className={submitBtnClassname} type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Logging in...
                    </button>
                    :
                    <button className={submitBtnClassname}>Login</button>
            }

        </form>
    )
}

export default SignInForm