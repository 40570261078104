import React, { useEffect, useRef, useState } from 'react'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import { useCloseSupportMutation, useGetSupportTicketByIdQuery, useSendMessageMutation } from '../../services/supportSlice'
import ApiErrorModal from '../../components/modal/ApiErrorModal'
import Loading from '../../components/ui/Loading'
import userPhoto from '../../assets/images/userPhoto.png'
import whatsappimg from '../../assets/images/whatsapp.png'
import phoneimg from '../../assets/images/phone.png'
import gmailimg from '../../assets/images/gmail.png'
import Api from '../../constants/Api'
import { Link } from 'react-router-dom'
import Image from '../../components/ui/Image'

const SupportChat = ({ id, res, closeSupport }) => {

    const chatContainerRef = useRef();

    const [image, setImage] = useState(null);


    const response = useGetSupportTicketByIdQuery(id, { pollingInterval: 5000 });

    const [sendMessage, sendMessageRes] = useSendMessageMutation()

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
    };


    const onSubmit = (e) => {
        e.preventDefault();
        const form = e.target
        if (form.checkValidity()) {
            const formData = new FormData();
            formData.append('message', form['Message'].value);
            if (image) formData.append('image', image);

            sendMessage({ id, body: formData })
                .then((res) => {
                    if (!res.error) {
                        response.refetch()
                        setImage(null)
                        form.reset()
                    }
                })
            form.classList.remove('was-validated')
        }
        else {
            form.classList.add('was-validated')
        }
    }


    const handleClose = () => {
        closeSupport({ id }).then((res1) => {
            if (!res1.error) {
                res.refetch()
                response.refetch()
            }
        })

    }

    useEffect(() => {
        // Scroll to the bottom of the chat container when the data changes
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [response.data]);

    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />


    return (
        <div className=' h-100 d-none d-lg-block'>

            {sendMessageRes.isError && <ApiErrorModal response={sendMessageRes} />}

            <div className=' h-100 rounded-4 d-flex flex-column justify-content-between'>

                <div className='d-flex justify-content-between align-items-center bg-light px-3 py-2 border-bottom'>

                    {/* <img className='me-2 rounded-circle shadow-sm object-fit-cover ' style={{ width: 40, height: 40 }} src={Api.BASE_URL + `uploads/images/profilePictures/${response.data.user.profilePicture.id}${response.data.user.profilePicture.ext}`} /> */}
                    <Image
                        src={Api.BASE_URL + `uploads/images/profilePictures/${response?.data?.user?.profilePicture?.id}${response?.data?.user?.profilePicture?.ext}`}
                        alt="profile"
                        className='me-2 rounded-circle shadow-sm object-fit-cover '
                        style={{ width: 40, height: 40 }}
                        icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                    />

                    <div className="flex-grow-1">
                        <div className='fs-13 fw-bold'>{response?.data?.user?.name}</div>
                        <div className='fs-11'>{response?.data?.user?.phoneNumber}, {response?.data?.user?.email}</div>
                    </div>

                    <Link to={"mailto:" + response?.data?.user?.email}>
                        <img className='me-2' src={gmailimg} style={{ width: 30 }} />
                    </Link>


                    <Link target={"_blank"} to={`https://wa.me/${response?.data?.user?.phoneNumber}`}>
                        <img className='me-2' src={whatsappimg} style={{ width: 30 }} />
                    </Link>

                    <Link to={"tel:" + response?.data?.user?.phoneNumber}>
                        <img className='me-2' src={phoneimg} style={{ width: 30 }} />
                    </Link>

                </div>

                <div className='d-flex justify-content-between align-items-center py-3 border-bottom'>
                    <h6 className='fw-bold mx-3'>{response?.data?.topic}</h6>
                    {(response?.data?.status == 'CLOSED') ? <p className='text-danger fw-bold me-3'>TICKET IS CLOSED</p> : <button className="btn btn-sm px-3 text-white rounded-pill mx-3" style={{ backgroundColor: '#D80027' }} onClick={handleClose}>Close Ticket</button>}
                </div>

                <div className='overflow-y-auto flex-grow-1' ref={chatContainerRef}>
                    {response.data.chats?.map((item, i) =>
                        <div className='d-flex flex-column py-3' key={i}>
                            {
                                item?.role === "superAdmin"
                                    ?
                                    <>
                                        <div className='d-flex w-75 align-self-end align-items-center mb-3'>
                                            <div className='bg-light px-3 pt-3 pb-1 me-2 rounded-start-4 rounded-bottom-4 flex-grow-1'>{item?.message}
                                                <div className='text-muted fs-13 text-end'>{new Date(item.createdAt).toLocaleString()}</div>
                                            </div>
                                            <div className='bg-danger rounded-circle d-flex align-items-center justify-content-center me-2 fw-bold' style={{ height: 35, width: 35 }}>S</div>

                                        </div>
                                        <div className='w-50 me-2 ms-auto'>
                                            {
                                                item?.image &&
                                                <img src={Api?.BASE_URL + `uploads/images/support/${item?.image?.id}${item?.image?.ext}`} className='w-100' />

                                            }
                                        </div>
                                    </>
                                    :
                                    (
                                        item.role === "Admin"
                                            ?
                                            <>
                                                <div className='d-flex w-75 align-self-start align-items-center mb-3'>
                                                    <div className=' rounded-circle overflow-hidden shadow-sm d-flex align-items-center justify-content-center ms-2' style={{ height: 35, width: 35 }}>
                                                        {/* <img src={Api.PROFILE_PICTURE_URL + item.admin.profilePicture.id + item.admin.profilePicture.ext} className='w-100 h-100' alt="" /> */}
                                                        <Image
                                                            src={Api?.PROFILE_PICTURE_URL + item?.admin?.profilePicture?.id + item?.admin?.profilePicture?.ext} alt="profile"
                                                            className='w-100 h-100'
                                                            icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                                                        />
                                                    </div>
                                                    <div className='px-3 pt-3 pb-1 ms-2 rounded-end-4 rounded-bottom-4' style={{ backgroundColor: "#EBEBEB" }}>{item.message}
                                                        <div className='text-muted fs-13 text-end'>{new Date(item.createdAt).toLocaleString()}</div>
                                                    </div>
                                                </div>
                                                <div className='w-50 me-auto ms-2'>
                                                    {
                                                        item?.image &&
                                                        <img src={Api.BASE_URL + `uploads/images/support/${item?.image?.id}${item?.image?.ext}`} className='w-100' />

                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='d-flex w-75 align-self-start align-items-center mb-3'>
                                                    <div className='rounded-circle overflow-hidden shadow-sm d-flex align-items-center justify-content-center ms-2' style={{ height: 35, width: 35 }}>
                                                        {/* <img src={Api.PROFILE_PICTURE_URL + item.user.profilePicture.id + item.user.profilePicture.ext} className='w-100 h-100' alt="" /> */}
                                                        <Image
                                                            src={Api?.PROFILE_PICTURE_URL + item?.user?.profilePicture?.id + item?.user?.profilePicture?.ext} alt="profile"
                                                            className='w-100 h-100'
                                                            icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                                                        />
                                                    </div>
                                                    <div className='px-3 pt-3 pb-1 ms-2 rounded-end-4 rounded-bottom-4' style={{ backgroundColor: "#ff6363" }}>{item.message}
                                                        <div className='text-muted fs-13 text-end'>
                                                            <div className='text-muted fs-13 text-end'>{new Date(item.createdAt).toLocaleString()}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='w-50 me-auto ms-2'>
                                                    {
                                                        item?.image && <img src={Api.BASE_URL + `uploads/images/support/${item?.image?.id}${item?.image?.ext}`} className='w-100' />
                                                    }
                                                </div>
                                            </>

                                    )
                            }
                        </div>
                    )}
                </div>

                {
                    (response.data.status != 'CLOSED') ?
                        <form className="" onSubmit={onSubmit}>
                            <div className='d-flex p-3'>

                                <div className='d-flex gap-2'>
                                    <div className="btn btn-sm text-white rounded-circle d-flex justify-conent-between align-items-center p-3" style={{ backgroundColor: '#D80027' }}>
                                        <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                            {Icons.plus('fs-5')}
                                        </label>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={handleFileInputChange}
                                        />
                                    </div>


                                    {
                                        image &&
                                        <div className="col">
                                            <label >
                                                <img
                                                    src={URL.createObjectURL(image)} className='object-fit-cover' alt="" style={{ height: 50, width: 50 }} />
                                            </label>
                                        </div>
                                    }
                                </div>
                                <Input name={'Message'} labelName={' '} containerClass={'w-100 mx-3'} required />
                                <button className='btn'>{Icons.send('fs-1', { color: '#D80027' })}</button>
                            </div>
                        </form>
                        :

                        <form className="" onSubmit={onSubmit}>
                            <div className='d-flex p-3'>
                                <button className="btn btn-sm text-white rounded-circle d-flex justify-conent-between align-items-center p-3" disabled style={{ backgroundColor: '#D80027' }}>{Icons.plus('fs-5')}</button>
                                <Input name={'Message'} containerClass={'w-100 mx-3'} required disabled />
                                <button className='btn' disabled>{Icons.send('fs-1', { color: '#D80027' })}</button>
                            </div>
                        </form>
                }
            </div>

        </div>
    )
}

export default SupportChat