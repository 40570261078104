export const transformDate = (date) => {
    return new Date(date).toString().split(" GMT")[0]
}

export const transformDateOnly = (date) => {
    return new Date(date).toDateString()
}

export function transformTimeOnly(dateString) {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, '0'); // Get hours in UTC
    const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Get minutes in UTC
    const seconds = date.getUTCSeconds().toString().padStart(2, '0'); // Get seconds in UTC

    if(hours>12)return `${(hours-12)}:${minutes}PM`;
    else return `${hours}:${minutes}AM`;

    
}