import { Route, Routes, } from 'react-router-dom';
import { useEffect, useState } from 'react';

import "./assets/scss/bootstrap.scss"
import "./assets/css/global.css"
import "./assets/css/fonts.css"
import "./assets/css/scrollbar.css"
import "./assets/css/settings.css"

import SignIn from './pages/SignIn';
import Layout from './components/layout/Layout';
import Dashboard from './pages/Dashboard';
import UserManagement from './pages/user/UserManagement';
import BranchManagement from './pages/branch/BranchManagement';
import ICGSPortals from './pages/icgsPortals/ICGSPortals';
import ResearchPortals from './pages/researchPortals/ResearchPortals';
import SPMKPortals from './pages/spmkPortals/SPMKPortals';
import CreateBranch from './pages/branch/CreateBranch';
import CreateUser from './pages/user/CreateUser';
import AdminManagement from './pages/admin/AdminManagement';
import CreateAdmin from './pages/admin/CreateAdmin';
import { useSelector } from 'react-redux';
import BrahmvidyaPortal from './pages/icgsPortals/BrahmvidyaPortal';
import Settings from './pages/setting/Settings';
import EducationPortal from './pages/icgsPortals/EducationPortal';
import ELearningPortal from './pages/icgsPortals/ELearningPortal';
import ManagementPortal from './pages/icgsPortals/ManagementPortal';
import VrcPortal from './pages/researchPortals/VrcPortal';
import GptPortal from './pages/researchPortals/GptPortal';
import WikiPortal from './pages/researchPortals/WikiPortal';
import AiBrahmPortal from './pages/researchPortals/AiBrahmPortal';
import KaryakarPortal from './pages/spmkPortals/SpmkKaryakarPortal';
import SpmkGurukulErpPortal from './pages/spmkPortals/SpmkGurukulErpPortal';
import SpmkBrahmvidyaPortal from './pages/spmkPortals/SpmkBrahmvidyaPortal';
import AyojanVibhagPortal from './pages/spmkPortals/AyojanVibhagPortal';
import EditBranch from './pages/branch/EditBranch';
import EditAdmin from './pages/admin/EditAdmin';
import EditUser from './pages/user/EditUser';
import ReminderManagement from './pages/reminder/ReminderManagement';
import Tickets from './pages/support/Tickets';
import MainBranchManagement from './pages/mainBranch/MainBranchManagement';
import CreateMainBranch from './pages/mainBranch/CreateMainBranch';
import EditMainBranch from './pages/mainBranch/EditMainBranch';
import SettingsV1 from './pages/setting/SettingsV1';


function App() {

  const isSignedIn = useSelector(state => state.auth.authToken);

  useEffect(() => { import('bootstrap') }, [])

  return (

    <Routes>

      {
        isSignedIn
          ?
          <Route path='/' element={<Layout />}>

            <Route index element={<Dashboard />} />

            <Route path="UserManagement">
              <Route index element={<UserManagement />} />
              <Route path="create" element={<CreateUser />} />
              <Route path=":id" element={<EditUser />} />
              <Route path="portal/:portal" element={<UserManagement />} />
            </Route>

            <Route path="MainBranchManagement">
              <Route index element={<MainBranchManagement />} />
              <Route path="create" element={<CreateMainBranch />} />
              <Route path=":id" element={<EditMainBranch />} />
            </Route>

            <Route path="BranchManagement">
              <Route index element={<BranchManagement />} />
              <Route path="create" element={<CreateBranch />} />
              <Route path=":id" element={<EditBranch />} />
            </Route>

            <Route path="AdminManagement">
              <Route index element={<AdminManagement />} />
              <Route path="create" element={<CreateAdmin />} />
              <Route path=":id" element={<EditAdmin />} />
              <Route path="portal/:portal" element={<AdminManagement />} />
            </Route>

            <Route path="ReminderManagement">
              <Route index element={<ReminderManagement />} />
            </Route>

            <Route path='ICGSPortals' >
              <Route index element={<ICGSPortals />} />
              <Route path="BrahmvidyaPortal" element={<BrahmvidyaPortal />} />
              <Route path="EducationPortal" element={<EducationPortal />} />
              <Route path="ELearningPortal" element={<ELearningPortal />} />
              <Route path="ManagementPortal" element={<ManagementPortal />} />
            </Route>

            <Route path='ResearchPortals' >
              <Route index element={<ResearchPortals />} />
              <Route path="VrcPortal" element={<VrcPortal />} />
              <Route path="GptPortal" element={<GptPortal />} />
              <Route path="WikiPortal" element={<WikiPortal />} />
              <Route path="AiBrahmPortal" element={<AiBrahmPortal />} />
            </Route>

            <Route path='SPMKPortals' >
              <Route index element={<SPMKPortals />} />
              <Route path="KaryakarPortal" element={<KaryakarPortal />} />
              <Route path="SaintsPortal" element={<SpmkGurukulErpPortal />} />
              <Route path="AyojanVibhagPortal" element={<AyojanVibhagPortal />} />
              <Route path="SpmkBrahmvidyaPortal" element={<SpmkBrahmvidyaPortal />} />
            </Route>


            <Route path='SettingV1' element={<SettingsV1 />} />
            <Route path='Settings' element={<Settings />} />
            <Route path='Tickets' element={<Tickets />} />

          </Route>
          :
          <Route path='/'>
            <Route index element={<SignIn />} />
            <Route path='*' element={<SignIn />} />
          </Route>
      }

    </Routes>

  );
}

export default App;
