import React from 'react'
import { useNavigate } from 'react-router-dom'

import bottomImg from "../../assets/images/image 53.png"

const SelectPortals = ({ headImg, portals }) => {

    const navigate = useNavigate()

    const renderPortal = (portal) =>
        <div key={portal.name} className="col cursor-pointer" onClick={portal.to ? () => navigate(portal.to) : () => { }}>
            <div className="card rounded-5 border-5 overflow-hidden shadow" style={{ borderColor: portal.color }}>
                <div><img src={portal.img} className='w-100 h-100 object-fit-cover' alt="" /></div>
            </div>
            <div className="card rounded-4 px-3 py-3 text-center shadow border-0" style={{ backgroundColor: portal.bgColor, marginTop: -50 }}>{portal.name}</div>
        </div>

    return (
        <div className="h-100 overflow-auto ">

            <div className="d-flex flex-column align-items-center justify-content-between h-100">

                <div className="container mb-5 mb-lg-0">
                    <div className="row row-cols-1 row-cols-lg-2 justify-content-center">
                        <div className="col">
                            <div className="bg-white text-center rounded-bottom-6 shadow overflow-hidden p-3 p-lg-4">
                                <img src={headImg} className='w-100' alt="" />
                            </div>
                        </div>
                    </div>
                </div>



                <div className="container px-5 mb-5 mb-lg-0">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 justify-content-center">
                        {portals.map(renderPortal)}
                    </div>
                </div>

                <div className="container mb-5 mb-lg-0">
                    <div className="row row-cols-1 row-cols-lg-2 justify-content-center">
                        <div className="col">
                            <div className="text-center fs-4 fw-bold">Select the portal you want to visit</div>
                            <img src={bottomImg} className='w-100' alt="" />
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )

}

export default SelectPortals